import apiWrapper from '../../privates/apiWrapper'
import Data from './data'
import Modes from './modes'
import RefComponents from './refComponents'
import Properties from './properties'
import Style from './style'
import Layout from './layout'
import Behaviors from './behaviors'
import Arrangement from './arrangement'
import Design from './design'
import Stylable from './stylable'
import {
  PageRef,
  ComponentRef,
  SDKContext,
  SDKDefaultContext,
  ContextAwareOptions,
  AppData,
} from '@wix/editor-platform-sdk-types'
import {createDefinition} from './structure'
import {ComponentDefinition} from './types'

export {ComponentDefinition}

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData
) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc Components
   * @note `Classic Editor` `Editor X`
   * @description Adds a new component with the given *componentDefinition* to the given page. To add a component and wait for layout adjustment
   * use [addAndAdjustLayout](#addAndAdjustLayout).
   * To connect your component or child components to a controller that is already on the stage, define connections on the component structure with the *controllerId* of the controller to connect to.
   * If you are concurrently adding an [appWidget](../articles/app-widgets.md), and the controller is therefore not yet on stage, the value of the *controllerId* must be the same value as the *id* of the AppController's data, as shown in the example.
   * This connects your newly-added component to the appWidget when it is added to the stage.
   * @example
   * const applicationId = this.applicationId;
   * const label = 'newLabel';
   * const currentPageRef = await editorSDK.document.pages.getCurrent('token');
   * const componentDefinition = {
   *                type: 'Container',
   *                componentType: 'platform.components.AppWidget',
   *                data: {applicationId: applicationId, label: label, type: 'AppController', id: 'myAppControllerId'},
   *                connections: {type: 'ConnectionList', items: [{type: 'ConnectionItem', role: 'appContainer', isPrimary: true, controllerId: 'myAppControllerId'}]}
   * }
   * const compRef = await editorSDK.document.components.add('token', {componentDefinition: componentDefinition, pageRef: currentPageRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - componentDefinition: Object containing the structure of the new component in the document.
   * - pageRef: Reference to the page that the component is added to.
   * - customId: Custom component ID for the added component.
   * - optionalIndex: Index of the component inside its container. Affects component's z index inside the container.
   * @returns A promise that is resolved with the *componentRef*, a reference to the new component.
   */
  function add(
    token: string,
    options: {
      componentDefinition: ComponentDefinition
      pageRef: PageRef
      customId?: string
      optionalIndex?: number
    }
  ): Promise<ComponentRef> {
    return apiWrapper.dsSetter(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.add(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Adds a new component with the given *componentDefinition* to the given page and then waits for layout adjustments.
   * @example
   * const applicationId = this.applicationId;
   * const label = 'newLabel';
   * const currentPageRef = await editorSDK.document.pages.getCurrent('token');
   * const componentDefinition = {
   *                componentType: 'wysiwyg.viewer.components.SiteButton',
   *                data: {applicationId: applicationId, label: label}
   * }
   * const compRef = await editorSDK.document.components.addAndAdjustLayout('token', {componentDefinition: componentDefinition, pageRef: currentPageRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - componentDefinition: Object containing the structure of the new component in the document.
   * - pageRef: Reference to the page that the component is added to.
   * - customId: Custom component ID for the appended component.
   * - optionalIndex: Index of the component inside its container. Affects component's z index inside the container.
   * @returns A promise that is resolved with the *componentRef*, a reference to the new component after layout adjustments were applied.
   */
  function addAndAdjustLayout(
    token: string,
    options: {
      componentDefinition: ComponentDefinition
      pageRef: PageRef
      customId?: string
      optionalIndex?: number
    }
  ): Promise<ComponentRef> {
    return apiWrapper.dsSetter(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.components.addAndAdjustLayout(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @note `Classic Editor` `Editor X`
   * @description Removes a component with the given componentRef.
   * If the editor is in mobile view (`viewMode == VIEW_MODES.MOBILE`) the component is hidden and not removed.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-1234'})
   * await editorSDK.components.remove('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - componentRef: A reference to the component to delete.
   * @returns A promise that is resolved when the component removal has been applied to the document.
   */
  function remove(token, options: {componentRef: ComponentRef}): Promise<null> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) => api.document.components.remove(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Returns a reference to the page (*pageRef*) that contains the given component.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const pageRef = editorSDK.components.getPage('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A reference to the component.
   * @returns A promise that is resolved with the *PageRef* object.
   */
  function getPage(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<PageRef> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.getPage(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Returns an array of all the component refs on the site.
   * @example
   * const allComponents = await editorSDK.document.components.getAllComponents('token');
   * allComponents.forEach(compRef => {});
   * @param {string} token - app token, not in use
   * @returns A promise that is resolved with an array of componentRefs.
   */
  function getAllComponents(token): Promise<ComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.components.getAllComponents(appData, token)
    )
  }

  /**
   * @doc Components
   * @description Returns an array of the connected component refs with the specified role and subRole.
   * @example
   * const customButtons = await editorSDK.document.components.findAllByRole('token', {
   *   controllerRef: {id: 'comp-kr1yqp6w', type: 'DESKTOP'},
   *   role: 'custom-btn',
   *   subRole: 'primary-btn'
   * });
   * @param token {string} - app token, not in use
   * @param options -
   * - controllerRef: A reference to the controller.
   * - role: The component's role name.
   * - subRole: The component's subRole name.
   * @returns A promise that is resolved with an array of componentRefs.
   */
  function findAllByRole(
    token,
    options: {controllerRef: ComponentRef; role: string; subRole?: string}
  ): Promise<ComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.controllerRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      async (api) => {
        const controllerConnections = await api.document.controllers.listControllerConnections(
          appData,
          token,
          {controllerRef: options.controllerRef}
        )

        return controllerConnections
          .filter(({connection}) => {
            if (connection.role !== options.role) {
              return false
            }

            return !options.subRole || connection.subRole === options.subRole
          })
          .map(({componentRef}) => componentRef)
      }
    )
  }

  /**
   * @doc Components
   * @description Returns an array of components of the provided type.
   * @example
   * const textComponents = await editorSDK.document.components.findAllByType('token', {
   *   componentType: 'wysiwyg.viewer.components.WRichText'
   * });
   * @param token - app token, not in use
   * @param options -
   *  - componentType: the type of a component.
   * @returns A promise that is resolved with an array of componentRefs of the provided type.
   */
  function findAllByType(
    token,
    options: {componentType: string}
  ): Promise<ComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      async (api) => {
        const componentRefs = await api.document.components.getAllComponents(
          appData,
          token
        )

        const componentsOfType = []

        for (const componentRef of componentRefs) {
          const componentType = await api.document.components.getType(
            appData,
            token,
            {componentRef}
          )

          if (componentType === options.componentType) {
            componentsOfType.push(componentRef)
          }
        }

        return componentsOfType
      }
    )
  }

  /**
   * @doc Components
   * @description Returns a reference to the component (*componentRef*) with the given ID.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - id: the ID of the component.
   * @returns A promise that is resolved with the *componentRef* of the component with the given ID.
   */
  function getById(token, options: {id: string}): Promise<ComponentRef> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: {id: options.id},
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.getById(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Returns the component type for the given component, identified by its *componentRef*.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const type = await editorSDK.components.getType('token', {componentRef});
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A reference to the component.
   * @returns A promise that is resolved with the component type of the given component, identified by its *componentRef*.
   */
  function getType(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<string> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.getType(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Returns the component's children.
   * @example
   * const containerComponentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const children = await editorSDK.components.getChildren('token', {componentRef: containerComponentRef});
   * children.forEach(compRef => {});
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A reference to the component.
   * - recursive: When set to *true*, returns all of the children recursively, in a flat array. When set to *false* (default), returns direct children.
   * - fromDocument:  When set to *true*, returns children from the document, not from the displayed components (default is *false*). Relevant for components that have different components when displayed (such as repeaters).
   * @returns A promise that is resolved with an array of componentRefs of the children components of the given `componentRef`.
   +*/
  function getChildren(
    token,
    options: {
      componentRef: ComponentRef
      recursive?: boolean
      fromDocument?: boolean
    }
  ): Promise<ComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.components.getChildren(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Returns all of the ancestors of the component.
   * @example
   * const compRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const ancestors = await editorSDK.components.getAncestors('token', {componentRef});
   * ancestors.forEach(compRef => {});
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A reference to the component.
   * @returns A promise that is resolved with an array of componentRefs of the ancestor components (including pages) of the given component, identified by its *componentRef*.
   +*/
  function getAncestors(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<ComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.components.getAncestors(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @note `Classic Editor` `Editor X`
   * @description Return the component's structure based on given structure properties.
   * @example
   * const compRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const compStructure = await editorSDK.components.get('token', {componentRefs: compRef, properties:['data','props']});
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A reference to the component.
   * - properties: An array of strings defining which fields from the structure are returned.
   * Possible properties are: *data*, *props*, *componentType*, *sdkType*, *connections*, *skin*, *style*, *role*.
   * - applicationId (Required only in Editor Extensions context): An ID of the application component's controller belongs to
   * @returns A promise that is resolved with the selected properties of the component's structure.
   +*/
  function get(
    token,
    options: ContextAwareOptions<
      Context,
      {componentRefs: ComponentRef | ComponentRef[]; properties?: string[]},
      {applicationId: number}
    >
  ): Promise<any> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRefs,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.get(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Returns the component's serialized structure.
   * @example
   * const compStructure = await editorSDK.document.components.serialize(token, {componentRef, maintainIdentifiers: true});
   * @param {string} token - app token, not in use
   * @param options -
   *  - componentRef: A reference to the component.
   *  - maintainIdentifiers: Boolean that when set to *true*, indicates that the serialized component should contain IDs.
   * @returns A promise that is resolved with the component's serialized structure.
   */
  function serialize(
    token,
    options: {componentRef: ComponentRef; maintainIdentifiers?: boolean}
  ): Promise<any> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.serialize(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Enables changing a component definition by passing  overrides (for example *componentType*, *skin*, *data*, *props*). Usually used for migrations using autopilot.
   * @example
   * await editorSDK.document.components.migrate(token, {componentRef, componentDefinition});
   * @param {string} token - app token, not in use
   * @param options -
   *  - componentRef: A reference to the component.
   *  - componentDefinition: The component definition with the props you want to override.
   * @returns A promise that is resolved once the component's migration is completed.
   */
  function migrate(
    token,
    options: {
      componentRef: ComponentRef
      componentDefinition: ComponentDefinition
    }
  ): Promise<void> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.migrate(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Checks whether the component referenced by *options.componentRef* is set to full width.
   * @example
   * const isFullWidth = await editorSDK.document.components.isFullWidth('token', {componentRef});
   * @param {string} token - app token, not in use
   * @param options -
   *  - componentRef: A reference to the component.
   * @returns A promise that is resolved with *true* if the component is set to full width, and *false* otherwise.
   */
  function isFullWidth(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<boolean> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.isFullWidth(appData, token, options)
    )
  }

  /**
   * @doc Components
   * @description Enlarges the component referenced by *options.componentRef* so that it fills the entire screen
   * horizontally (with optional margins on the sides), or shrinks it to fit the container, depending on the value
   * you set for *options.fullWidth*.
   * @example
   * await editorSDK.document.components.setFullWidth(
   *   'token',
   *   {
   *     componentRef,
   *     fullWidth: true,
   *     margins: {left: {vw: 10}, right: {vw: 10}}
   *   }
   * )
   * @param {string} token - app token, not in use
   * @param {Object} options -
   *  - componentRef: A reference to the component to stretch or shrink.
   *  - fullWidth: A Boolean value that went set to *true* causes the component to stretch to full size, and when set to *false*, shrinks it to fit the container.
   *  - margins: An optional object defining margins for the full-size component.
   *    - left: An object with a CSS unit as key and a number as value,
   *    specifying how wide the left margin should be. Allowed units are *px* and *vw*. Default is `{vw: 0}`.
   *    - right: An object with a CSS unit as key and number as value,
   *    specifying how wide the right margin should be. Allowed units are *px* and *vw*. Default is `{vw: 0}`.
   * @returns {Promise} A Promise that is resolved when the changes are applied.
   */
  function setFullWidth(
    token,
    options: {
      componentRef: ComponentRef
      fullWidth: boolean
      margins?: {
        left?: {vw: number} | {px: number}
        right?: {vw: number} | {px: number}
      }
    }
  ): Promise<void> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.setFullWidth(appData, token, options)
    )
  }

  return {
    layout: Layout(appData),
    modes: Modes(appData),
    properties: Properties(appData),
    style: Style(appData),
    data: Data(appData),
    behaviors: Behaviors(appData),
    design: Design(appData),
    stylable: Stylable(appData),
    add,
    addAndAdjustLayout,
    remove,
    getPage,
    getAllComponents,
    findAllByRole,
    findAllByType,
    getById,
    getType,
    getChildren,
    getAncestors,
    get,
    serialize,
    migrate,
    isFullWidth,
    setFullWidth,
    createDefinition,
    arrangement: Arrangement(appData),
    refComponents: RefComponents<Context>(appData),
  }
}
