import {ComponentRef} from '@wix/editor-platform-sdk-types'
import {getAPI} from '../../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Mobile
   * @description re add component to mobile structure. You can re add only components that were hidden before.
   * @example editorSDK.document.mobile.hiddenComponents.show('token', {componentRef: componentRef})
   * @param token - app token - not in use
   * @param options -
   *  - componentRef: component reference of the component we want to show on mobile structure
   * @returns a promise the is resolved once the component is re added to mobile structure.
   */
  function show(
    token: string,
    options: {componentRef: ComponentRef}
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.mobile.hiddenComponents.show(appData, token, options)
    })
  }

  return {
    hiddenComponents: {
      show,
    },
  }
}
