import {getAPI} from '../privates/editorAPI'
import {ComponentRef} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @doc editorComponents
   * @note `Classic Editor`
   * @description This method moves a component to the page footer.
   * This method works only if the component can be moved to the footer.
   * @example
   * editorSDK.editor.components.moveToFooter('token', {componentRef, shouldScrollToComponent: false})
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: the given componentRef.
   * - shouldScrollToComponent: scroll to the component after it moves to the footer. Default is *true*.
   * @returns a promise that is resolved when the component is moved.
   */
  function moveToFooter(
    token,
    options: {componentRef: ComponentRef; shouldScrollToComponent: boolean}
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.components.moveToFooter(appData, token, options)
    )
  }

  return {
    moveToFooter,
  }
}
