import {
  ComponentRef,
  Mode,
  PageGroupDataPointer,
} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc Modes
   * @note `Classic Editor` `Editor X`
   * @description Add the given component to the given pagesGroup.
   * The component will only be visible in the pages that are related to the given pages group.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const groupRef = await editorSDK.document.pagesGroup.getById(appDefinitionId, {pagesGroupId: 'comp-jhq292f'})
   * await editorSDK.document.components.modes.showComponentOnlyOnPagesGroup('token', {componentRef, pagesGroupPointer: groupRef});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: the component ref.
   * - pagesGroupPointer: the pagesGroup ref to add the component to.
   * @returns a promise that is resolved when the changes has applied.
   */
  function showComponentOnlyOnPagesGroup(
    token,
    options: {
      componentRef: ComponentRef
      pagesGroupPointer: PageGroupDataPointer
      componentPointer?: ComponentRef
    }
  ): Promise<void> {
    const compRef = options.componentRef
      ? options.componentRef
      : options.componentPointer
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [compRef, options.pagesGroupPointer],
        operationTypes: compOperationType,
      },
      (api) =>
        api.document.components.modes.showComponentOnlyOnPagesGroup(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Modes
   * @description Activate mode for the given component.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const modes = await editorSDK.document.components.modes.getModes('token', {componentRef});
   * await editorSDK.document.components.modes.activateComponentMode('token', {componentRef, modeId: modes[0].modeId});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: the component ref.
   * - modeId: the mode id that should be activated.
   * @returns a promise that is resolved when the changes has applied.
   */
  function activateComponentMode(
    token,
    options: {componentRef: ComponentRef; modeId: string}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) =>
        api.document.components.modes.activateComponentMode(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Modes
   * @description returns the modes of the given component.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * await editorSDK.document.components.modes.getModes('token', {componentRef});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: the component ref.
   * @returns a promise that resolved with the modes.
   */
  function getModes(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<Mode> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.modes.getModes(appData, token, options)
    )
  }

  /**
   * @doc Modes
   * @description Apply the current component to all modes.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * await editorSDK.document.components.modes.applyCurrentToAllModes('token', {componentRef});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: the component ref.
   * @returns a promise that is resolved when the changes has applied.
   */
  function applyCurrentToAllModes(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) =>
        api.document.components.modes.applyCurrentToAllModes(
          appData,
          token,
          options
        )
    )
  }

  return {
    showComponentOnlyOnPagesGroup,
    applyCurrentToAllModes,
    activateComponentMode,
    getModes,
  }
}
