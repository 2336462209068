import {ComponentRef, ResponsiveLayout} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc ResponsiveLayout
   * @description returns the responsive layout for the component. Will break on non responsive editor, as responsiveLayout module is not loaded in Document Services
   * @example //origin is parameter application gets in the editorReady method
   * if (origin === 'responsive') { const responsiveLayout = await editorSDK.document.responsiveLayout.get('token', {componentRef}); }
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - `componentRef`: component reference for the component to get responsive layout
   * @returns a promise that is resolved with responsive layout.
   */
  function get(
    token: string,
    options: {componentRef: ComponentRef}
  ): Promise<ResponsiveLayout> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.responsiveLayout.get(appData, token, options)
    )
  }

  /**
   * @doc ResponsiveLayout
   * @description updates the responsive layout for the component. Will break on non responsive editor, as responsiveLayout module is not loaded in Document Services
   * @example //origin is parameter application gets in the editorReady method
   * const responsiveLayout: ResponsiveLayout = {
   *   type: 'LayoutData',
   *   componentLayouts: [],
   *   itemLayouts: [],
   *   containerLayouts: [],
   *   id: 'id',
   *   metaData: 'meta'
   * }
   * if (origin === 'responsive') {
   *  await editorSDK.document.responsiveLayout.update('token', {componentRef, responsiveLayout});
   * }
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - `componentRef`: component reference for the component to get responsive layout
   * - `responsiveLayout`: new responsive layout
   * @returns a promise that is resolved when the layout update call is finished
   */
  function update(
    token: string,
    options: {componentRef: ComponentRef; responsiveLayout: ResponsiveLayout}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) => api.document.responsiveLayout.update(appData, token, options)
    )
  }

  return {
    get,
    update,
  }
}
