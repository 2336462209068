import {getAPI, getAPIWithPlatformContext} from './privates/editorAPI'
import progressBarPanelUtil from './privates/progressBarPanelUtil'
import * as pmrpc from 'pm-rpc'
import {getApiName, isWorker, API_TYPES} from './privates/utils'
import {getAppExportedApis, setAppExportedApi} from './privates/workerSdk'
import Environment from './editor/environment'
import Ide from './editor/ide/ide'
import Panel from './editor/panel'
import Accessibility from './editor/accessibility'
import Info from './editor/info'
import Components from './editor/components'
import Preview from './editor/preview'
import Selection from './editor/selection'
import DeveloperMode from './editor/developerMode'
import {
  AppData,
  ContextAwareOptions,
  PageRef,
  SDKContext,
  SDKDefaultContext,
  ComponentRef,
  Link,
  ElementData,
  ElementCategoryData,
  AddComponentHandler,
  RemoveComponentHandler,
  ImagePreset,
  DisplayNamePreset,
  CustomizeButtonOptions,
  PanelTag,
  ComponentsOnAddElements,
} from '@wix/editor-platform-sdk-types'
import Site from './editor/userPreferences/site'
import Deeplink from './editor/deeplink'
import EditorUtils from './editor/utils'
import {
  ImageMediaResult,
  DocumentMediaResult,
  VideoMediaResult,
  MediaType,
  MediaTypeStrings,
  PanelType,
  PanelTypeStrings,
  PanelResolveType,
  PagesPanelTabType,
  PagesPanelTabTypeStrings,
  NotificationType,
  NotificationTypeStrings,
  NotificationPresetTypes,
  NotificationPresetTypeStrings,
  FontStyleParamData,
  PremiumIntent,
  PremiumIntentStrings,
} from '@wix/editor-platform-sdk-types'
import Routers from './editor/routers'
import {resolveOption} from '../utils/utils'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData
) {
  /**
   * @doc Editor
   * @note `Classic Editor`
   * @example
   * const returnedValue = await editorSDK.editor.openChangeVariationsPanel(token, {
   *     presets: [
   *         {
   *             variationId: 'c1dmp',
   *             mainImageUrl: 'image://v1/ca8093_a69289df5e8849d1b7703ed78f10bd68~mv2.png/571_217/ca8093_a69289df5e8849d1b7703ed78f10bd68~mv2.png',
   *             presetWidth: 100
   *         }
   *     ],
   *     title: 'Panel Title', //optional
   *     helpId: 'e9f90ca5-0616-4676-ab67-e54162f082b3', //optional
   *     eventName: 'changedVariation', //optional
   *     customizeButton: { //optional
   *      action: 'Custom',
   *      config: {
   *         url: 'https://example.com',
   *         initialData: {}
   *      },
   *     },
   *     keepOverrides: true //optional
   * });
   * @param token - app token - not in use
   * @param options -
   *  - presets: An array of the variations. These are displayed in a gallery of images when you provide *mainImageURL*, or as radioButtons labeled with each *displayName*, if you don't provide images.
   *  Each array member includes:
   *      - variationId: taken from Internals -> Dev Center Settings -> Widget Data.
   *      - mainImageUrl: relative URL from media manager.
   *      - presetWidth: Optional. The image width (px) in the panel (not the original image width).
   *      - displayName: the variation name to label the radio button if *mainImageUrl* is not provided.
   *  - title: Optional. The panel title. Defaults to Design.
   *  - helpId: Optional. The help ID for the topic to open in the help center. Defaults to 'e9f90ca5-0616-4676-ab67-e54162f082b3'.
   *  - eventName: Optional. When passed, a click on a preset triggers an event with this eventName, with a payload containing the variationId. The Editor doesn't automatically switch variations. The application can change variations by calling editorSDK.document.application.appStudioWidgets.changeVariation().
   *  - customizeButton: Optional. Displays the Customize Design button and allows defining its onClick behavior. Has two properties:
   *      - action: 'Custom': Displays an iFrame using the URL you provide in the config object.
   *      - config: Object containing:
   *          - url: The URL may be absolute (for example, https://example.com), or relative to the editor script URL (for example, ./settings.html)
   *          - initialData: The initial data to be passed to the panel. The panel gets this data in the `startConfiguration` event payload as `initialData`.
   *  - keepOverrides: Optional. Applies to user data and style overrides. Default value is *true* (keep overrides). Set to *false* to reset overrides.
   * @description Opens a change variations panel relative to an appWidget or byRef component.
   * This is usually opened from the GFPP design button.
   * The panel displays a gallery of variation images or radioButtons with the displayName of each variation.
   * Each image or radio button click changes the widget variation on stage. Learn more in [this article](../articles/variations.md).
   * @returns This promise is resolved when the panel is closed.
   */
  function openChangeVariationsPanel(
    token: string,
    options: {
      presets: ImagePreset[] | DisplayNamePreset[]
      title?: string
      helpId?: string
      eventName?: string
      keepOverrides?: boolean
      customizeButton?: CustomizeButtonOptions
    }
  ): Promise<any> {
    return getAPI().then((api) =>
      api.editor.openChangeVariationsPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const returnedValue = await editorSDK.editor.openComponentPanel(token, {
   *     url: './settings.html',
   *     title: 'My Component Settings',
   *     initialData: {some: 'data'},
   *     componentRef: theCompRef,
   *     type: editorSDK.editor.PanelType.Settings
   *     // OR
   *     // type: 'settings'
   * });
   * @param token - app token - not in use
   * @param options -
   *  - url: The panel's URL.
   * The URL can be absolute (for example, https://example.com), or relative to the editor script URL (for example, ./settings.html).
   *  - componentRef: The reference to the component.
   *  - width: The panel's width.
   *  - height: The panel's height.
   *  - title: The panel's title.
   *  - type: The type of panel to be opened. Affects the *selected* property of the corresponding GFPP button.
   *  - initialData: The initial data to be passed to the panel.
   *  - displayEssentialBanner: Configures whether a banner stating that essential elements were deleted is displayed. Default is *true*.
   *    Only applies to an appWidget or byRef widget that can open an Add Elements panel.
   * @description Opens a panel relative to a component.
   * The panel gets its data in the `startConfiguration` event payload as `initialData`.
   * This is usually the type of panel to be opened from the GFPP. Learn more in [this article](../articles/application-panels.md)
   * @returns A promise including the value from `closePanel` (or `undefined` if not closed from code).
   * This promise is resolved when the panel is closed.
   */
  function openComponentPanel(
    token: string,
    options: {
      url: string
      componentRef: ComponentRef
      width?: number
      height?: number
      title?: string
      displayEssentialBanner?: boolean
      initialData?: any
      type?: PanelTypeStrings
    }
  ): Promise<any> {
    return getAPI().then((api) =>
      api.editor.openComponentPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.openNativeComponentPanel(token, 'settings', {
   *     componentRef: theCompRef,
   *     panelSectionsDefinition: {
   *       placeholder: hidden,
   *       required: hidden,
   *     },
   *    helpId: '779577ad-08d2-442a-b7d2-1052ff48a996'
   * });
   * @param token - app token - not in use
   * @param nativePanelType Panel type. Can be `settings`, `layout`, `manage`, `tableManage` or `design`. For example, `settings` for the native Editor settings panel, or `tableManage` for the native Editor manage table panel.
   * @param options
   *  - componentRef: The reference to the component for which the panel is opened.
   *  - panelSectionsDefinition: Sections definition override to be passed as panel properties. The application can
   *  choose to hide, show or disable the sections based on the properties. *This is only relevant for a native Editor settings panel (nativePanelType === 'settings').*
   * - helpId: Replaces the default helpId for the panel.
   * @description Opens the Editor's native component panel (for example, the GFPP setting native panel). `panelSectionsDefinition` can be
   * used to override default sections in the Editor's panel. Learn more in [this article](../articles/customized-editor-components-panels.md).
   * @returns A promise that's rejected if the provided component is not connected by a primary connection
   * to the application, or if the panel type is unknown. The promise resolves once the panel is closed.
   */
  function openNativeComponentPanel(
    token: string,
    nativePanelType: string,
    options: {
      componentRef: ComponentRef
      panelSectionsDefinition?: object
      helpId?: string
    }
  ): Promise<any> {
    return getAPI().then((api) =>
      api.editor.openNativeComponentPanel(appData, nativePanelType, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @deprecated Use specific panel type functions instead.
   * @description This method is deprecated. Use specific panel type functions instead.
   * @returns A promise that is resolved with the value from `closePanel` (or `undefined` if not closed from code).
   * This promise is resolved when the panel is closed.
   */
  function openPanel(panelName, panelProps, leavePanelsOpen) {
    return getAPI().then((api) =>
      api.editor.openPanel(appData, panelName, panelProps, leavePanelsOpen)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const returnedValue = await editorSDK.editor.openModalPanel(token, {
   *     url: './modal.html',
   *     title: 'Are you sure you want to delete this?',
   *     initialData: {some: 'data'},
   * });
   * @param token - app token - not in use
   * @param options -
   *  - url: The panel's URL.
   * The URL may be absolute (for example, https://example.com), or relative to the editor script URL (for example, ./settings.html).
   *  - width:  The panel's width.
   *  - height: The panel's height.
   *  - title: The panel's title.
   *  - type: The type of panel to be opened. Affects the *selected* property of the corresponding GFPP button.
   *  - centered: If set to *true*, aligns the panel vertically correctly when panel has no default modal header.
   *  - shouldHideHeader: If set to *true*, the modal's header is hidden.
   *  - initialData: The initial data to be passed to the panel.
   * The panel gets this data in the `startConfiguration` event payload as `initialData`.
   * @description Opens a modal panel in the center of the screen. Learn more in [this article](../articles/application-panels.md).
   * @returns A promise to the value from `closePanel` (or `undefined` if not closed from code).
   * This promise is resolved when the panel is closed.
   */
  function openModalPanel(
    token: string,
    options: {
      url: string
      width?: number
      height?: number
      title?: string
      initialData?: any
      type?: PanelTypeStrings
      centered?: boolean
      shouldHideHeader?: boolean
    }
  ): Promise<any> {
    return getAPI().then((api) =>
      api.editor.openModalPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const returnedValue = await editorSDK.editor.openToolPanel(token, {
   *     url: './filter.html',
   *     title: 'Create Filter',
   *     initialData: {some: 'data'},
   * });
   * @param token - app token - not in use
   * @param options -
   *  - url: The panel's URL.
   * The URL may be absolute (for example, https://example.com), or relative to the editor script URL (for example, ./settings.html).
   *  - width:  The panel's width
   *  - height: The panel's height
   *  - title: The panel's title,
   *  - type: The type of  panel to be opened. Affects the *selected* property of the corresponding GFPP button.
   *  - initialData:  The initial data to be passed to the panel.
   * The panel gets this data in the `startConfiguration` event payload as `initialData`.
   * @description Opens a tool panel relative to another open panel.
   * This panel is usually opened from another open panel (for example, as a way to select a complex value). Learn more in [this article](../articles/application-panels.md).
   * @returns A promise to the value from `closePanel` (or `undefined` if not closed from code).
   * This promise is resolved when the panel is closed.
   */
  function openToolPanel(
    token,
    options: {
      url: string
      width?: number
      height?: number
      title?: string
      initialData?: any
      type?: PanelTypeStrings
    }
  ): Promise<any> {
    return getAPI().then((api) =>
      api.editor.openToolPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor`
   * @example
   * const returnedValue = await editorSDK.editor.openSidePanel(token, {
   *     url: './filter.html',
   *     initialData: {some: 'data'},
   * });
   * @param token - app token - not in use
   * @param options -
   *  - url: The panel's URL.
   * The URL may be absolute (for example, https://example.com), or relative to the editor script URL (for example, ./settings.html).
   *  - initialData:  The initial data to be passed to the panel.
   * The panel gets this data in the `startConfiguration` event payload as `initialData`.
   *  - displayAboveModals: When set to `true`, indicates that the panel should be displayed above open modal
   *  windows in Editor (i.e. not be overlaid by them). Default is `true`. Note that this indicates a preference,
   *  Editor may still decide to overlay the side panel depending on the type of modal currently open.
   * @description Opens a docked side panel in the editor. Learn more in [this article](../articles/application-panels.md).
   * @returns A promise to the value from `closePanel` (or `undefined` if not closed from code).
   * This promise is resolved when the panel is closed.
   */
  function openSidePanel(
    token,
    options: {
      url: string
      initialData?: any
      displayAboveModals?: boolean
    }
  ): Promise<any> {
    const defaultOptions = {displayAboveModals: true}
    return getAPI().then((api) =>
      api.editor.openSidePanel(appData, token, {...defaultOptions, ...options})
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const returnedValue = await editorSDK.editor.openFullStagePanel(token, {
   *     url: './settings.html',
   *     initialData: {some: 'data'},
   * });
   * @param token - app token - not in use
   * @param options -
   *  - url: The panel's URL.
   * The URL may be absolute (for example, https://example.com), or relative to the editor script URL (for example, ./settings.html).
   *  - initialData:  Initial data to be passed to the panel.
   * The panel gets this data in the `startConfiguration` event payload as `initialData`.
   * @description Opens a panel that takes up the whole screen.
   * This panel is used, for example, for displaying collections in data binding. Learn more in [this article](../articles/application-panels.md).
   * @returns A promise to the value from `closePanel` (or `undefined` if not closed from code).
   * This promise is resolved when the panel is closed.
   */
  function openFullStagePanel(
    token,
    options: {
      url: string
      initialData?: any
    }
  ): Promise<any> {
    return getAPI().then((api) =>
      api.editor.openFullStagePanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const newLink = await editorSDK.editor.openFullStagePanel(token, {
   *   value: currentLinkValue
   * });
   * @param token - app token - not in use
   * @param options -
   *  - value: The current value of the link.
   *  - showLinkTargetSection: a flag to display the Link Target section in the Link Panel. Default is *false*.
   * @description
   * Opens a panel prompting the user to choose a link for the component.
   * @returns A promise to the link object representing the link selected. Resolves once the link panel is closed.
   */
  function openLinkPanel(
    token,
    options: {value?: Link; showLinkTargetSection?: boolean}
  ): Promise<Link> {
    return getAPI().then((api) =>
      api.editor.openLinkPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const colorPicker = await editorSDK.editor.openColorPicker(token, {
   *   color: '#FF3232',
   *   position: {
   *     x: -12,
   *     y: 72
   *   }
   * }, onColorChanged);
   * @param token - app token - not in use
   * @param options -
   *  - color: The current color. Can be a theme color or a hex value, such as `#12FF34`.
   *  - position: How much to offset the color picker from the current panel position on the x and y axes.
   * @param onColorChanged A callback that's called whenever the user selects a color, with an object of the structure {color, isHover}. This callback gets called when the user selects a color.
   * @description Opens a panel prompting the user to choose a color.
   * @returns A promise that is resolved when the color picker is closed.
   */
  function openColorPicker(
    token,
    options: {
      color?: string
      position?: {
        x: number
        y: number
      }
    },
    onColorChanged: (colorSelection: {
      color: string
      theme: string
      isHover: boolean
    }) => void
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.openColorPicker(appData, token, options, onColorChanged)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example const fontPickerIsClosed = await editorSDK.editor.openFontPicker(token, {
   *     position: {
   *         x: 50,
   *         y: 100
   *     },
   *     title: 'Custom font picker',
   *     panelSectionsDefinition: {
   *         theme: 'enabled',
   *         font: 'enabled',
   *         size: 'enabled',
   *         style: 'hidden',
   *         htmlTag: 'enabled',
   *     },
   *     fontMaxSize: 15,
   *     fontMinSize: 5,
   *     componentStyle: {
   *         preset: 'Custom',
   *         family: 'arial',
   *         size: 10,
   *         style: {
   *             bold: true,
   *             italic: false,
   *             underline: true
   *         },
   *         htmlTag: 'h3'
   *     }
   * })
   * @param token - app token - not in use
   * @param options -
   * - position
   * - position.x: The position of the font picker panel on the X axis.
   * - position.y: The position of the font picker panel on the Y axis.
   * - title: The title of the font picker panel. The default title is "Font Picker".
   * - panelSectionsDefinition.theme: Hides the theme selection dropdown menu. Allowed values: *enabled* (default) and *hidden*.
   * - panelSectionsDefinition.font: Hides the font selection dropdown menu. Allowed values: *enabled* (default) and *hidden*.
   * - panelSectionsDefinition.size: Hides the font size slider. Allowed values: *enabled* (default) and *hidden*.
   * - panelSectionsDefinition.style: Hides the font style controls (bold, italic, underline). Allowed values: *enabled* (default) and *hidden*.
   * - panelSectionsDefinition.htmlTag: Displays the HTML tag selection dropdown menu. Allowed values: *enabled* and *hidden* (default).
   * - fontMaxSize: The maximum font size allowed.
   * - fontMinSize: The minimum font size allowed.
   * - componentStyle: The state of the font picker's controls. This is an object that a user can read with the [editorSDK.document.tpa.getStyleParams()](../Document/TPA.md#getstyleparams) method and write using the [editorSDK.document.tpa.setStyleParams()](../Document/TPA.md#setstyleparams) method.
   * - componentStyle.preset: The name of the selected preset (use *Custom* for a custom preset).
   * - componentStyle.editorKey: The ID of the selected theme (ignored if preset is *Custom*).
   * - componentStyle.family: The name of the selected font family.
   * - componentStyle.size: The selected font size.
   * - componentStyle.style.bold: Boolean that is *true* when the bold style is selected.
   * - componentStyle.style.italic:  Boolean that is *true* when the italic style is selected.
   * - componentStyle.style.underline:  Boolean that is *true* when the underline style is selected.
   * - componentStyle.htmlTag: The selected HTML tag.
   * @param onStyleChanged - A callback that's called whenever the user changes any of the font style settings, includes an object of the structure [optionName, optionValue, additionalOptionalOptionData].
   * @description Opens a panel prompting the user to set a font style.
   * @returns A promise that is resolved when the font picker is closed.
   */
  function openFontPicker(
    token: string,
    options: {
      position?: {
        x: number
        y: number
      }
      title?: string
      panelSectionsDefinition?: Partial<{
        theme: 'hidden' | 'enabled'
        font: 'hidden' | 'enabled'
        size: 'hidden' | 'enabled'
        style: 'hidden' | 'enabled'
        htmlTag: 'hidden' | 'enabled'
      }>
      fontMaxSize?: number
      fontMinSize?: number
      componentStyle?: Pick<
        FontStyleParamData,
        'preset' | 'editorKey' | 'family' | 'size' | 'style' | 'htmlTag'
      >
    },
    onStyleChanged: (
      styleChange:
        | [string, string | boolean | number, string]
        | [string, string]
    ) => void
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.openFontPicker(appData, token, options, onStyleChanged)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const images = await editorSDK.editor.openMediaPanel(token, {
   *   isMultiSelect: true
   * });
   * @param options -
   *  - mediaType: The type of media to open the panel for (defaults to `MediaType.Image`).
   *  - isMultiSelect: Whether the user can select multiple items.
   * @description Opens the media manager for the specified category and returns an array of the results.
   * @returns An array of the results, or *false* if none were chosen.
   */
  function openMediaPanel(
    token,
    options: {mediaType: MediaTypeStrings; isMultiSelect: boolean}
  ): Promise<
    | ImageMediaResult[]
    | DocumentMediaResult[]
    | VideoMediaResult[]
    | null
    | undefined
  > {
    return getAPI().then((api) =>
      api.editor.openMediaPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @deprecated Use `openPagesPanel` instead.
   * @description This method is deprecated. Use `openPanesPanel` instead.
   * @returns A promise that is resolved once the panel is closed with the value passed from close method.
   */
  function openPagePanel(token, panelProps: {pageRef: PageRef}) {
    return getAPI().then((api) =>
      api.editor.openPagePanel(appData, token, panelProps)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Opens the pages panel.
   * If a `pageRef` is specified, it opens the pages panel on a specific page.
   * If `initialSettingsTabType` is specified, it opens a specific tab in the pages panel.
   * @example
   * await openPagesPanel(token, {
   *   pageRef,
   *   renameEnabled: false,
   *   initialSettingsTabType: editorSDK.editor.PagesPanelTabType.PageInfo
   *   // OR
   *   // initialSettingsTabType: 'PAGE_INFO'
   * });
   * @param token - app token - not in use
   * @param panelProps -
   *  - pageRef: The specific page to open the panel for.
   *  - renameEnabled: Specifies whether it is possible to rename a page.
   *  - initialSettingsTabType: The tab to open the settings panel for.
   *  - origin: A field for the BI event that is sent when the pages panel is opened.
   * @returns A promise that is resolved once the panel is closed with the value passed from close method.
   */
  function openPagesPanel(
    token,
    panelProps: {
      pageRef?: PageRef
      renameEnabled?: boolean
      initialSettingsTabType: PagesPanelTabTypeStrings
      origin?: string
    }
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.openPagesPanel(appData, token, panelProps)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const canAddStatic = await canAddStaticPage(token);
   * @param token - app token - not in use
   * @description Returns *true* if the Editor allows the user to add a static page, *false* if not.
   * @returns A promise that contains whether the Editor allows the user to add a static page.
   */
  function canAddStaticPage(token): Promise<boolean> {
    return getAPI().then((api) => api.editor.canAddStaticPage(appData, token))
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.openHelpPanel(token, {helpId, anchorId});
   * @param token - app token - not in use
   * @param options
   *  - helpId: The help ID for the topic to open in the help center.
   *  - anchorId: The ID of the anchor to navigate to within the panel.
   * @description Opens the help center at a specified topic, and if an anchorId is provided, to a specific section in the topic. For example, if you provide the helpId *219ea7a3-96dc-4e44-a143-97495ea532d6*,
   * and the anchorId *types-of-common-issues-to-look-for*, this method will open the [Zoom Content topic](https://support.wix.com/en/article/zoom-content) at the [Types of Common Issues to Look For](https://support.wix.com/en/article/zoom-content#types-of-common-issues-to-look-for) section.
   * @returns A promise that is resolved once the panel is closed with the value passed from close method.
   */
  function openHelpPanel(
    token,
    options: {helpId: string; anchorId?: string}
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.openHelpPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.closePanel(token, resolveValue);
   * @param token - The panel's token, as supplied by the `startConfiguration` payload.
   * @param value - The value to resolve the corresponding *open panel* method that opened this panel.
   * @description Closes the panel and resolves the promise returned from the opening method with the specified value. This method should be called from inside the panel.
   * Learn more in [this article](../articles/application-panels.md).
   * @returns -  A promise that is resolved when the panel is closed
   */
  function closePanel(token, value: any): Promise<void> {
    return getAPI().then((api) => {
      api.editor.closePanel(appData, token, value)
    })
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example editorSDK.editor.updatePanelOptions(token, {
   *   title: 'New Title'
   * });
   * @param token - The panel's token, as supplied by the `startConfiguration` payload.
   * @param panelProps - A partial object of the properties to update.
   * @description Update the current panel's properties.
   * @returns A promise that is resolved once the options is updated.
   */
  function updatePanelOptions(
    token,
    panelProps: {
      url?: string
      width?: number
      height?: number
      title?: string
      initialData?: any
      type?: PanelTypeStrings
    }
  ) {
    return getAPI().then((api) => {
      api.editor.updatePanelOptions(appData, token, panelProps)
    })
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @deprecated Expose as part of app exports instead [Expose App APIs](../articles/app-exported-apis.md).
   * @example
   * await editorSDK.editor.setAppAPI(token, myAppAPI);
   * @param token - app token - not in use
   * @param appApi - The app API
   * @param options (Required only in Editor Extensions context):
   *  - appDefinitionId - The application's appDefinitionId.
   * @description Sets a private API for the app, to be used from its panels.
   * This allows the panels to perform complex actions that are not interrupted if the user closes the panel.
   * This should be a dictionary of functions, and all their parameters should either be completely serializable, or functions.
   * @returns A Promise, which is resolved once the app API is set in the Editor, ready to be shared by panels.
   */
  function setAppAPI(
    token,
    appApi: any,
    options: ContextAwareOptions<Context, void, {appDefinitionId: string}>
  ): Promise<void> {
    return getAPI().then((api) => {
      const appDefinitionId = resolveOption(
        appData,
        options,
        'appDefinitionId',
        {
          isRequired: true,
        }
      )
      const apiName = setAppExportedApi(
        appDefinitionId,
        appApi,
        API_TYPES.PRIVATE
      )

      // needed for compatibility with older sdk versions
      // TODO: remove once verified no apps are effected
      pmrpc.api.set(String(appData.applicationId), appApi)

      return api.editor.setAppAPI(
        appData,
        token,
        Object.assign(options || {}, {apiName})
      )
    })
  }

  /**
   * @doc Editor
   * @note `Classic Editor`
   * @example
   * const appAPI = await editorSDK.editor.getAppAPI()
   * @description Gets the app API defined in the worker in `setAppAPI`.
   * @param options (Required only in Editor Extensions context):
   *  - appDefinitionId - The application's appDefinitionId.
   * @returns A promise to the app API, that will be resolved with the app API if the app has set one.
   */
  function getAppAPI(
    options: ContextAwareOptions<Context, void, {appDefinitionId: string}>
  ): Promise<any> {
    if (isWorker()) {
      const apis = getAppExportedApis(appData.appDefinitionId) || {}
      return apis[API_TYPES.PRIVATE]
    }

    return new Promise((resolve) => {
      resolve(
        resolveOption(appData, options, 'appDefinitionId', {isRequired: true})
      )
    }).then((appDefinitionId) => {
      return pmrpc.api.request(getApiName(appDefinitionId, API_TYPES.PRIVATE), {
        target: parent,
      })
    })
  }

  /**
   * @doc Editor
   * @note `Classic Editor`
   * @deprecated Use `editorSDK.document.save` instead.
   * @description This method is deprecated. Use [`editorSDK.document.save`](../Document/Document.md#save) instead.
   * @returns A promise that is resolved when site is saved.
   */
  function save(token) {
    return getAPI().then((api) => api.editor.save(appData, token))
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @deprecated Use `showUserActionNotification` function instead.
   * @description This method is deprecated. Use the `showUserActionNotification` function instead.
   * @example
   * const linkWasClicked = await editorSDK.editor.showNotification(token, {
   *   title: 'You Just deleted something important!',
   *   message: 'You just deleted the submit button.',
   *   type: editorSDK.editor.NotificationType.Warning, // OR type: 'warning'
   *   link: {caption: 'Restore'}
   * });
   * @param token - app token - not in use
   * @param options -
   *  - title: The optional title of the notification.
   *  - message: The main text of the notification.
   *  - type: The notification's type.
   *  - link: The optional link displayed in the notification.
   * @returns A promise that is resolved when the notification is closed. Its resolved value is *true* if the link was clicked.
   */
  function showNotification(
    token,
    options: {
      title?: string
      message: string
      type: NotificationTypeStrings
      link: {caption: string}
    }
  ): Promise<true | undefined> {
    return getAPI().then((api) =>
      api.editor.showNotification(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const linkWasClicked = await editorSDK.editor.showUserActionNotification(token, {
   *   title: 'You Just deleted something important!',
   *   message: 'You just deleted the submit button.',
   *   type: editorSDK.editor.NotificationType.Warning,
   *   link: {caption: 'Restore'}
   * });
   * @param token - app token - not in use
   * @param options -
   *  - title: The optional title of the notification (appears only in Editor X).
   *  - message: The main text of the notification.
   *  - type: The notification's type (determines the notification color in Wix Editor, not relevant in Editor X).
   *  - link: The optional link displayed in the notification.
   * @description Shows a notification with the specified text in response to a specific user interaction. [Learn More](../articles/notifications.md)
   * @returns A promise that is resolved when the notification is closed. Its resolved value is *true* if the link was clicked.
   */
  function showUserActionNotification(
    token,
    options: {
      title?: string
      message: string
      type: NotificationType
      link: {caption: string}
    }
  ): Promise<true | undefined> {
    return getAPI().then((api) =>
      api.editor.showUserActionNotification(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * const linkWasClicked = await editorSDK.editor.showPresetUserActionNotification(token, {
   *   preset : 'repeaterMaxItemsEditorLimitation'
   *   // OR
   *   // preset: editorSDK.editor.NotificationPresetTypes.REPEATER_EDITOR_MAX_ITEMS
   * });
   * @param token - app token - not in use
   * @param options
   *  - preset: The preset name (needs to be from NotificationPresetTypes)
   * @description Shows a predefined notification for the passed preset.
   * @returns A promise that is resolved when the notification is closed. Its resolved value is *true* if the link is clicked.
   */
  function showPresetUserActionNotification(
    token,
    options: {
      preset: NotificationPresetTypeStrings
    }
  ): Promise<true | undefined> {
    return getAPI().then((api) =>
      api.editor.showPresetUserActionNotification(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor`
   * @example
   * const PremiumIntent = editorSDK.editor.PremiumIntent;
   * editorSDK.editor.openBillingPage(token, {premiumIntent: PremiumIntent.Paid});
   * // OR
   * // editorSDK.editor.openBillingPage(token, {premiumIntent: 'PAID'})
   * @param token - app token - not in use
   * @param options
   *  - premiumIntent: The premium intent for the billing page.
   *  - referrer: The referrer for the billing page.
   *  @description Opens a modal to upgrade the app.
   *  Note: This function does not open a new tab directly, since popup blockers block tabs that are opened
   *  because of events from outside the main thread.
   * @returns A promise to the value from `closePanel` (or `undefined` if not closed from code).
   * This promise is resolved when the panel is closed.
   */
  function openBillingPage(
    token,
    options: {
      premiumIntent?: PremiumIntentStrings
      referrer?: string
    }
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.openBillingPage(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * await editorSDK.editor.updateAppState({languages: ['fr', 'heb']});
   * @example
   *  const mapStateToProps = ({state}) => {
   *    return { appsStore: stateManagement.editorPlugins.appsStore.selectors.getAppStore(state, this.props.appDefinitionId) }
   *  }
   * @param {Object} partialState -
   * @description Use this method to inform Editor components of state changes in a platform app.
   * For example, use this method to tell the Editor that an app successfully added a language.
   * In order to listen to the changes that have been broadcast by `updateAppState`, you need to implement a `mapStateToProps` function in your component in santa-editor.
   * See example. The appsStore is now a property in your component, available as `this.props.appsStore`.
   * When you invoke `updateAppState,` the appsStore prop is also updated. You can then use a React lifecycle method, such as `componentDidUpdate` to define how to handle the update.
   * @returns a promise that is resolved when app state is updated.
   */
  function updateAppState(partialState): Promise<void> {
    return getAPI().then((api) => {
      return api.editor.updateAppState(appData, partialState)
    })
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.openDashboardPanel(token, {url: 'wix-forms', closeOtherPanels: false});
   * @param token - app token - not in use
   * @param options
   *  - url: The relative URL in the dashboard.
   *  - closeOtherPanels: set to *true* if you want to close all open panels when opening the dashboard panel.
   * @description Opens a modal with the business manager dashboard. Learn more in [this article](../articles/application-panels.md).
   * @returns A promise to the value from `closePanel` (or `undefined` if not closed from code).
   * This promise is resolved when the panel is closed.
   */
  function openDashboardPanel(
    token,
    options: {
      url: string
      closeOtherPanels: boolean
    }
  ): Promise<any> {
    return getAPI().then((api) =>
      api.editor.openDashboardPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Opens a progress bar panel in the center of the screen. Learn more in [this article](../articles/native-panels.md#progress-bar).
   * @param token - app token - not in use
   * @param options -
   *  - title: The title of progress bar.
   *  - totalSteps: The total number of steps of the progress bar.
   *  - currentStep: Optional. The step on which to start the bar. The default initial step is 0.
   *  - stepTitle: Optional. The title of the currentStep.
   *  - image` - Optional. The URL to the src of the image. The image's layout will be 84 x 84 px.
   * @example
   * await editorSDK.editor.openProgressBar(token, {
   *     title: 'Installing App',
   *     totalSteps: 5
   * });
   * @returns A promise that is resolved when the app opens the panel or is rejected if there is already an open progress bar panel.
   */
  function openProgressBar(
    token: string,
    options: {
      title: string
      totalSteps: number
      currentStep?: number
      stepTitle?: string
      image?: string
    }
  ): Promise<void> {
    return progressBarPanelUtil.openProgressBar(appData, token, options)
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Updates the progress bar panel with new values. Works only if the panel was opened by the same app. Learn more in [this article](../articles/native-panels.md#progress-bar).
   * @param token - app token - not in use
   * @param options -
   *  - currentStep: The current step of the progress bar.
   *  - stepTitle: Optional. Step title, empty by default.
   * @example
   * await editorSDK.editor.openProgressBar(token, {
   *     title: 'Installing App',
   *     totalSteps: 5
   * });
   * @example
   * await editorSDK.editor.updateProgressBar(token, {
   *     currentStep: 2,
   *     stepTitle: 'Step Title'
   * });
   * @returns A promise that is resolved when the panel is updated, or rejected if the update is not by the app that opened the panel.
   */
  function updateProgressBar(
    token: string,
    options: {
      currentStep: number
      stepTitle?: string
    }
  ): Promise<void> {
    return progressBarPanelUtil.updateProgressBar(appData, token, options)
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Closes the progress bar panel after 1000 ms. If `isError` is set to *true*, opens default error panel.
   * Works only if the panel was opened by the same app. Learn more in [this article](../articles/native-panels.md#progress-bar).
   * @param token - app token - not in use
   * @param options -
   *  - isError: Optional. If set to *true* will open a default error panel.
   * @example
   * await editorSDK.editor.openProgressBar(token, {
   *     title: 'Installing App',
   *     totalSteps: 5
   * });
   * @example
   * await editorSDK.editor.closeProgressBar(token, {
   *     isError: true
   * });
   * @example
   * await editorSDK.editor.closeProgressBar(token);
   * @returns A promise that is resolved when the panel is closed, or rejected if the close command is not by the app that opened the panel.
   */
  function closeProgressBar(
    token: string,
    options: {
      isError?: boolean
    }
  ): Promise<void> {
    return progressBarPanelUtil.closeProgressBar(appData, token, options)
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Opens a custom error panel in the center of the screen. Learn more in [this article](../articles/native-panels.md#error-panel).
   * @param token - app token - not in use
   * @param options -
   *  - headerText: The title of the error panel.
   *  - shouldShowIllustration: Set to *true* to display the illustration on the panel, otherwise set to *false* (no default value).
   *  - topDescriptionText: Description text at the top of the panel. Translations should be provided by the app.
   *    The topDescriptionText can include tags to be converted to HTML. For more information, including how to format your tags and content, see [HTML parser](../articles/native-panels.md#html-parser).
   *  - bottomDescriptionText: Optional. Description text at the bottom of the panel. Translations should be provided by the app.
   *    The bottomDescriptionText can include tags to be converted to HTML. For more information, including how to format your tags and content, see [HTML parser](../articles/native-panels.md#html-parser).
   *  - illustration: Optional. The *absolute* URL to the src of the image. Defaults to *destructive* illustration.
   *  - secondaryActionText: Optional. The button text for the secondary action on the panel. Defaults to *Cancel*.
   *  - mainActionText: The button text for the main action on the panel.
   *  - helpId: Optional. The help ID of the topic to open in the help center.
   * @example
   *  await editorSDK.editor.openErrorPanel(token, {
   *     headerText: 'wait',
   *     shouldShowIllustration: true,
   *     illustration: 'https://example.com/media/images/image1.png',
   *     topDescriptionText: 'Are you sure you want to delete this page?',
   *     mainActionText: 'Delete'
   * });
   * @returns A promise that is resolved when the panel closes with one of the following enums (PanelResolveType):
   * MAIN_ACTION: The main action button was clicked.
   * SECONDARY_ACTION: The secondary action button was clicked.
   * CLOSE_ACTION: The 'X' (close) button was clicked.
   */
  function openErrorPanel(
    token: string,
    options: {
      headerText: string
      shouldShowIllustration: boolean
      illustration?: string
      topDescriptionText: string | PanelTag[]
      bottomDescriptionText?: string | PanelTag[]
      secondaryActionText?: string
      mainActionText: string
      helpId?: string
    }
  ): Promise<PanelResolveType> {
    return getAPI().then((api) =>
      api.editor.openErrorPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Opens a custom confirmation panel in the center of the screen.  Learn more in [this article](../articles/native-panels.md#confirmation-panel).
   * @param token - app token - not in use
   * @param options -
   *  - headerText: The title of the confirmation panel.
   *  - shouldShowIllustration: Set to *true* to display the illustration on the panel, otherwise set to *false* (no default value).
   *  - descriptionText: Description text at the center of the panel. Translations should be provided by the app as a rich text paragraph.
   *    The descriptionText can include tags to be converted to HTML. For more information, including how to format your tags and content, see [HTML parser](../articles/native-panels.md#html-parser).
   *  - illustration: Optional. The *absolute* URL to the src of the image. Defaults to *confirmation* (bandage) illustration.
   *  - secondaryActionText: Optional. The button text for the secondary action on the panel.
   *  - mainActionText: The button text for the main action on the panel.
   *  - helpId: Optional. The help ID of the topic to open in the help center.
   * @example
   * await editorSDK.editor.openConfirmationPanel(token, {
   *     headerText: 'wait',
   *     shouldShowIllustration: true,
   *     illustration: 'https://example.com/media/images/image1.png',
   *     descriptionText: 'Are you sure you want to delete this page?',
   *     mainActionText: 'OK'
   * });
   * @returns A promise that is resolved when the panel closes with one of the following enums (PanelResolveType):
   * MAIN_ACTION: The main action button was clicked.
   * SECONDARY_ACTION: The secondary action button was clicked.
   * CLOSE_ACTION: The 'X' (close) button was clicked.
   */
  function openConfirmationPanel(
    token: string,
    options: {
      headerText: string
      shouldShowIllustration: boolean
      illustration?: string
      descriptionText: string | PanelTag[]
      secondaryActionText?: string
      mainActionText: string
      helpId?: string
    }
  ): Promise<PanelResolveType> {
    return getAPI().then((api) =>
      api.editor.openConfirmationPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Opens the Manage Content panel.
   * @param token - app token - not in use
   * @param options -
   *  - title: The title of the Manage Content panel.
   *  - descriptionText: Description text at the center of the panel. Translations should be provided by the app as a rich text paragraph.
   *  - mainActionText: The button text for the main action on the panel.
   *  - secondaryActionText: Optional. The button text for the secondary action on the panel.
   *  - helpId: Optional. The help ID of the topic to open in the help center.
   * @example
   * await editorSDK.editor.openManageContentPanel(token, {
   *     title: 'Change Image',
   *     descriptionText: 'This image element displays content from Database Collection “Cities”.',
   *     mainActionText: 'Change from Collection',
   *     secondaryActionText: 'Modify connections'
   * });
   * @param token
   * @returns A promise that is resolved when the panel closes with one of the following enums (PanelResolveType):
   * MAIN_ACTION: The main action button was clicked.
   * SECONDARY_ACTION: The secondary action button was clicked.
   * CLOSE_ACTION: The 'X' (close) button was clicked.
   */

  function openManageContentPanel(
    token: string,
    options: {
      title: string
      descriptionText: string
      mainActionText: string
      secondaryActionText?: string
      helpId?: string
    }
  ): Promise<PanelResolveType> {
    return getAPI().then((api) =>
      api.editor.openManageContentPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Opens a custom promotional panel in the center of the screen.  Learn more in [this article](../articles/native-panels.md#promotional-panel).
   * @param token - app token - not in use
   * @param options -
   *  - titleText: The title of the promotional panel.
   *  - subtitleText: Optional. The subtitle of the promotional panel.
   *  - contentArray: Description text at the center of the panel, should be 3-5 bullets. Translations should be provided by the app.
   *  - illustration: Optional. The *absolute* URL to the src of the image.
   *  - mainActionText: The button text for the main action on the panel.
   *  - helpId: Optional. The help ID of the topic to open in the help center. Used by both the "?" and the KBLinkText.
   *  - KBLinkText: Optional. The link text under the main action, will open a topic in the help center. Appears only if a helpId is provided.
   * @example
   * await editorSDK.editor.openPromotionalPanel(token, {
   *     titleText: 'title',
   *     subtitleText: 'subtitle',
   *     illustration: 'https://example.com/media/images/image1.png',
   *     contentArray: ['item1', 'item2'],
   *     mainActionText: 'OK'
   * });
   * @returns A promise that is resolved when the panel closes with one of the following enums (PanelResolveType):
   * MAIN_ACTION: The main action button was clicked.
   * CLOSE_ACTION: The 'X' (close) button was clicked.
   */
  function openPromotionalPanel(
    token: string,
    options: {
      titleText: string
      subtitleText?: string
      illustration?: string
      contentArray: string[]
      KBLinkText?: string
      mainActionText: string
      helpId?: string
    }
  ): Promise<PanelResolveType> {
    return getAPI().then((api) =>
      api.editor.openPromotionalPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Opens a platform *Add Elements* panel as a widget component panel (only in DESKTOP view).
   * @param token - app token - not in use
   * @param options -
   *  - components: An array of all the components that are to appear on the Add Elements panel.
   *  This array must have an object containing a unique ID for each component (the component role is unique and convenient) and whether the component is essential.
   *  - widgetRef: A pointer to the appWidget so that the newly-opened panel is attached to it.
   *  - shouldHaveReset: If set to *true*, adds the *Snap To Latest Version* button to the panel. Default value is *false*.
   * @example
   * await editorSDK.editor.openAddElementsPanel(token, {
   *     components: [{label: 'button4', isEssential: true, essentialText: 'this button is mandatory'}],
   *     widgetRef: {id: 'comp-ke3t5sa', type: 'DESKTOP'}, shouldHaveReset: false
   * });
   * @returns A promise that is resolved when the panel opens. The promise is rejected if the widgetRef is not an appWidget.
   */
  function openAddElementsPanel(
    token: string,
    options: {
      widgetRef: ComponentRef
      components: ComponentsOnAddElements[]
      shouldHaveReset?: boolean
    }
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.openAddElementsPanel(appData, token, options)
    )
  }

  /**
   * @doc Editor
   * @note `Classic Editor` `Editor X`
   * @description Opens a new *Elements* panel as a widget component panel (only in desktop view). Learn more in [this article](../articles/native-panels.md#elements-panel).
   * @param token - app token - not in use
   * @param options -
   *  - widgetRef: A pointer to the appWidget so that the newly-opened panel is attached to it.
   *  - elementsData: Data for elements that are to appear on the Elements panel.
   *  - categoriesData: Data about categories inside the panel (optional).
   *  - addComponentHandler: A callback that should add the selected component and any other relevant components to the stage. This function should return an array of `ElementIdentifier`s, which represent components added to the stage that should be selected in the panel.
   *  - removeComponentHandler: A callback that should remove the selected component and any other relevant components from the stage. This function should return an array of `ElementIdentifier`s, which represent components removed from the stage that should be deselected in the panel.
   * @example
   *  const widgetRef = {id: 'comp-kr3af86d', type:'DESKTOP'} ;
   *  const categoriesData = [{
   *    id:'cat1',
   *    title: 'some-title',
   *      tooltipData: {
   *        content: 'Some nice translated and very long long long content',
   *        link: {
   *           helpId: 'www.example.com',
   *           text: 'here is some text'
   *         }
   *       }
   *   }, {
   *     id:'cat2',
   *     title: 'another-title',
   *   }]
   * const elementsData = [{
   *    label: 'Text',
   *    identifier: {role: 'text1'},
   *    categoryId: 'cat1',
   *    tooltipData: {
   *        content: 'Some nice translated and very long long long content',
   *        link: {
   *           helpId: '331-saf-fffasfaf-ggfgdfhf-fff',
   *           text: 'here is some text'
   *         }
   *     }
   * },
   * {
   *     label: 'Button',
   *     identifier: {role: 'button1'},
   *     categoryId: 'cat2',
   *     tooltipData: {
   *         content: 'Some nice transadfadsgsdgsdgsdgcontent',
   *         link: {
   *           helpId: '331-saf-fffasfaf-ggfgdfhf-fff',
   *           text: 'here is some text'
   *         }
   *   }
   * }]
   * const addCompHandler = async ({ role, subRole }) => {
   *    return [{role: 'role3', subRole: 'subRole3'}]
   * }
   * const removeCompHandler = async (compRef) => {
   *  return [{role: 'field_role_text_area'}, {role: 'role3', subRole: 'subRole3'}]
   * }
   * await sdk.editor.openElementsPanel('t', {widgetRef, categoriesData, elementsData, addComponentHandler: addCompHandler, removeComponentHandler: removeCompHandler })
   * @returns A promise that is resolved when the panel opens. The promise is rejected if the widgetRef is not an appWidget.
   */
  function openElementsPanel(
    token: string,
    options: {
      widgetRef: ComponentRef
      helpId?: string
      elementsData: ElementData[]
      categoriesData?: ElementCategoryData[]
      addComponentHandler: AddComponentHandler
      removeComponentHandler: RemoveComponentHandler
    }
  ): Promise<void> {
    const {
      addComponentHandler,
      removeComponentHandler,
      ...serializableOptions
    } = options
    return getAPI().then((api) => {
      return api.editor.openElementsPanel(
        appData,
        token,
        serializableOptions,
        addComponentHandler,
        removeComponentHandler
      )
    })
  }

  function wrapFunctionsWithPreventOnSilent<
    Fns extends Record<string, (...args: any[]) => Promise<any> | Promise<void>>
  >(functionsToPreventExecutionOnSilent: Fns): Fns {
    const clonedFunctions = {...functionsToPreventExecutionOnSilent}
    for (const functionName of Object.keys(clonedFunctions)) {
      clonedFunctions[functionName as keyof Fns] = ((...args: any) => {
        return getAPIWithPlatformContext().then(({platformContext}) => {
          if (platformContext.isSilent) {
            return Promise.resolve()
          }
          return functionsToPreventExecutionOnSilent[functionName](...args)
        })
      }) as Fns[keyof Fns]
    }
    return clonedFunctions
  }

  // Add here functions that you want to prevent execution and return Promise.resolve()
  const objToPrevent = {
    openBillingPage,
    openHelpPanel,
    closePanel,
    updatePanelOptions,
    openComponentPanel,
    openNativeComponentPanel,
    openChangeVariationsPanel,
    openPanel,
    openToolPanel,
    openSidePanel,
    openFullStagePanel,
    openLinkPanel,
    openColorPicker,
    openFontPicker,
    openMediaPanel,
    openPagePanel,
    openPagesPanel,
    openModalPanel,
    openProgressBar,
    openDashboardPanel,
    updateProgressBar,
    closeProgressBar,
    openErrorPanel,
    openConfirmationPanel,
    openManageContentPanel,
    openPromotionalPanel,
    openAddElementsPanel,
    openElementsPanel,
  }

  const preventedWhenSilentInstall = wrapFunctionsWithPreventOnSilent(
    objToPrevent
  )

  return {
    accessibility: Accessibility(appData),
    info: Info(appData),
    preview: Preview(appData),
    selection: Selection(appData),
    panel: Panel(appData),
    environment: Environment(appData),
    components: Components(appData),
    ide: Ide(appData),
    utils: EditorUtils(appData),
    userPreferences: {
      site: Site(appData),
    },
    developerMode: DeveloperMode(appData),
    routers: Routers(appData),
    deeplink: Deeplink(appData),
    openComponentPanel,
    openNativeComponentPanel,
    openChangeVariationsPanel,
    openPanel,
    openToolPanel,
    openSidePanel,
    openFullStagePanel,
    openLinkPanel,
    openColorPicker,
    openFontPicker,
    openMediaPanel,
    openPagePanel,
    openPagesPanel,
    canAddStaticPage,
    setAppAPI,
    getAppAPI,
    save,
    showNotification,
    showUserActionNotification,
    showPresetUserActionNotification,
    updateAppState,
    PanelType,
    MediaType,
    PagesPanelTabType,
    NotificationType,
    NotificationPresetTypes,
    PremiumIntent,
    openBillingPage,
    openHelpPanel,
    closePanel,
    updatePanelOptions,
    openModalPanel,
    openProgressBar,
    openDashboardPanel,
    updateProgressBar,
    closeProgressBar,
    openErrorPanel,
    openConfirmationPanel,
    openManageContentPanel,
    openPromotionalPanel,
    openAddElementsPanel,
    openElementsPanel,
    ...preventedWhenSilentInstall,
  }
}
