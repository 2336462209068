import {getAPI} from '../../privates/editorAPI'
import TreePane from './treePane'

export default function (appData) {
  /**
   * @doc Ide
   * @note `Classic Editor` `Editor X`
   * @description Receives a path and function name and opens the file at the selected function name. If the function name is not sent, it will navigate to the top of the file.
   * The function also receives `caretOffset`, which allows to set the pointer at a specific offset within the line.
   * @example editorSDK.editor.ide.openFile('token', {path: 'backend/routers.js',functionName: 'test_Router'})
   * @param {string} token - app token, not in use
   * @param options -
   * - `path`: The path to the file you want to navigate to. The root directory can be: backend, public, pages, or schemas.
   * - `functionName`: Optional parameter that describes the function you want to navigate to.
   * - `caretOffset`:  Optional parameter that describes the character offset in the line.
   * @returns a promise that is resolved once the file was navigate to
   */
  function openFile(
    token,
    options: {path: string; functionName?: string; caretOffset?: number}
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.editor.ide.openFile(appData, token, options)
    })
  }

  /**
   * @doc Ide
   * @note `Classic Editor`
   * @description the function get a collection name and navigate to the collection.
   * @example editorSDK.editor.ide.navigateToCollection('token', {name: 'animalsCollection'});
   * @param {string} token - app token, not in use
   * @param options -
   * - `name`: collection name
   * @returns a promise that is resolved once the collection was opened
   */
  function navigateToCollection(
    token: string,
    options: {name: string}
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.editor.ide.navigateToCollection(appData, token, options)
    })
  }

  return {
    openFile,
    navigateToCollection,
    treePane: TreePane(appData),
  }
}
