let editorAPI
let _editorAPIReadyPromise
import queue from './SDKoperationsQueue'

const set = function (api, key, value) {
  const steps = key.split('.')
  let pointer = api

  steps.forEach((step, index, array) => {
    if (index === array.length - 1) {
      pointer[step] = value
    }

    if (!pointer[step]) {
      pointer[step] = {}
    }

    pointer = pointer[step]
  })

  return api
}

const generateRunTimeAPIFromDescription = function (apiMap) {
  let api = {}
  for (const key in apiMap) {
    if (apiMap.hasOwnProperty(key)) {
      api = set(api, key, apiMap[key])
    }
  }
  return api
}

/**
 * @returns {Promise} - The api that is used by the SDK.
 */
export function getAPI() {
  return new Promise(function (resolve) {
    if (editorAPI) {
      resolve(editorAPI)
    } else {
      _editorAPIReadyPromise = resolve
    }
  })
}

export function setAPI(apiMap) {
  if (!apiMap) {
    editorAPI = undefined
    _editorAPIReadyPromise = undefined
    return
  }
  editorAPI = generateRunTimeAPIFromDescription(apiMap)
  queue.setWaitChangesApplied(editorAPI.document.waitForChangesApplied)
  if (_editorAPIReadyPromise) {
    _editorAPIReadyPromise(editorAPI)
  }
}

async function tryToRun(fn, alternative) {
  const result = typeof fn === 'function' ? await fn() : alternative
  return result
}

async function getplatformContext(api) {
  const isSilent = await tryToRun(api.editor.info.isSilentInstall, false)
  return {
    isSilent,
  }
}

export async function getAPIWithPlatformContext() {
  const editorAPI = await getAPI()

  return {
    api: editorAPI,
    platformContext: await getplatformContext(editorAPI),
  }
}
