import {PageRef, PageData, RouterRef} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP
  const routerOperationType = apiWrapper.OPERATION_TYPES.ROUTER
  /**
   * @doc RouterPages
   * @note `Classic Editor` `Editor X`
   * @description Adds a dynamic page for the router. The router can navigate only to the dynamic page that belongs to the router. [Learn more](../articles/routers.md)
   * @example const validObj = await editorSDK.document.routers.pages.add('token', {routerRef, pageTitle: 'pageTitle', pageRoles:['role1']})
   * @param token - app token - not in use
   * @param options -
   *  - routerRef: The router reference  you want to add the page to.
   *  - pageTitle: String describing the pageTitle. This title is seen on the editor pageMenu and ideTree.
   *  - pageRoles: Array of strings that describing the page roles
   * @returns A promise that is resolved when the page is added. The return value is the PageRef that was added to the router.
   */
  function add(
    token: string,
    options: {routerRef: RouterRef; pageTitle: string; pageRoles?: string[]}
  ): Promise<PageRef> {
    return apiWrapper.dsSetter(
      {
        compRefsToAwait: options.routerRef,
        operationTypes: [compOperationType, routerOperationType],
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.routers.pages.add(appData, token, options)
    )
  }

  /**
   * @doc RouterPages
   * @note `Classic Editor` `Editor X`
   * @description deletes a dynamic page connected to the router. The function gets a Router reference and page reference and deletes the page
   * if it's belongs to the router and the router reference is owned by the application. [Learn more](../articles/routers.md)
   * @example
   * const anotherPageRef = {id: 'another-page'}
   * editorSDK.document.routers.pages.remove('token',{pageRef, routerRef, pageToNavigateAfterRemove: anotherPageRef})
   * @param options -
   *  - routerRef: The router reference to which the page that is being removed is assigned.
   *  - pageRef : The reference to the page that is removed.
   *  - pageToNavigateAfterRemove : The page to navigate to after the removal (supported only in Classic Editor).
   * @returns a promise that is resolved once the page is removed.
   */
  function remove(
    token: string,
    options: {
      routerRef: RouterRef
      pageRef: PageRef
      pageToNavigateAfterRemove: PageRef
    }
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [options.pageRef, options.routerRef],
        operationTypes: [compOperationType, routerOperationType],
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.routers.pages.delete(appData, token, options)
    )
  }

  /**
   * @doc RouterPages
   * @description adds existing page reference to the routers. This action will convert the page from static page to be a dynamic pages under the given
   * router. This action will allow the router to navigate to this page. [Learn more](../articles/routers.md)
   * @example editorSDK.document.routers.pages.connect('token', {pageRef, routerRef, pageRoles: ['role1']});
   * @param token - app token - not in use
   * @param options -
   *  - routerRef: the router reference that owns the page you want to remove.
   *  - pageRef : the page reference of the page you want to remove
   *  - pageRoles : array of string describing the pageRoles
   * @returns a promise that is resolved once the page is connected to the router
   */
  function connect(
    token: string,
    options: {routerRef: RouterRef; pageRef: PageRef; pageRoles?: string[]}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [options.pageRef, options.routerRef],
        operationTypes: [compOperationType, routerOperationType],
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.routers.pages.connect(appData, token, options)
    )
  }

  /**
   * @doc RouterPages
   * @description removes page from the router. This action will convert a dynamic page belongs to the router to a static page.
   * This action will not allow the router to navigate to this page anymore. [Learn more](../articles/routers.md)
   * @example editorSDK.document.routers.pages.disconnect('token', {pageRef, routerRef});
   * @param token - app token - not in use
   * @param options -
   *  - routerRef: the router reference that owns the page you want to remove.
   *  - pageRef : the page reference of the page you want to remove.
   * @returns a promise that is resolved once the page is disconnected from the router.
   */
  function disconnect(
    token: string,
    options: {routerRef: RouterRef; pageRef: PageRef}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [options.pageRef, options.routerRef],
        operationTypes: [compOperationType, routerOperationType],
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.routers.pages.disconnect(appData, token, options)
    )
  }

  /**
   * @doc RouterPages
   * @description moves page from router to router. This action will move a dynamic page belongs to one router to a different router. [Learn more](../articles/routers.md)
   * @example
   * const fromRouterRef = {id: 'router2'}
   * const toRouterRef = {id: 'router1'}
   * editorSDK.document.routers.pages.move('token', {fromRouterRef, toRouterRef, pageRef})
   * @param token - app token - not in use
   * @param options -
   *  - fromRouterRef: router reference that represent the router we want to move the page from.
   *  - toRouterRef:   router reference that represent the router we want to move the page to.
   *  - pageRef : the page reference we want to remove. need to belong to the fromRouterRef router.
   * @returns a promise that is resolved once the page is moved from one router to the other.
   */
  function move(
    token: string,
    options: {
      fromRouterRef: RouterRef
      toRouterRef: RouterRef
      pageRef: PageRef
    }
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [
          options.fromRouterRef,
          options.toRouterRef,
          options.pageRef,
        ],
        operationTypes: [compOperationType, routerOperationType],
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.routers.pages.move(appData, token, options)
    )
  }

  /**
   * @doc RouterPages
   * @description returns an array of pageRef and pageData of pages that can become dynamic pages. page can be dynamic if:
   * - its not home page.
   * - its not tpa page.
   * - it's not a dynamic page.
   * [Learn more](../articles/routers.md)
   * @example editorSDK.document.routers.pages.listConnectablePages('token')
   * @param token - app token - not in use
   * @returns a promise the is resolved with array of pageRef and pageData that can become dynamic pages.
   */
  function listConnectablePages(
    token: string
  ): Promise<[{pageRef: PageRef; pageData: PageData}]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: [routerOperationType, compOperationType],
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.routers.pages.listConnectablePages(appData, token)
    )
  }

  return {
    add,
    remove,
    disconnect,
    move,
    listConnectablePages,
    connect,
  }
}
