/*eslint @typescript-eslint/no-unused-vars: ["error", { "argsIgnorePattern": "token" }]*/
import {ComponentRef} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../privates/apiWrapper'
import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc Selection
   * @note `Classic Editor` `Editor X`
   * @example
   * const selectedComponents = await editorSDK.editor.selection.getSelectedComponents();
   * @param {string} token - app token, not in use
   * @description Returns an array of the selected components.
   * @returns A Promise that is resolved with the array of the selected components.
   */
  function getSelectedComponents(token?: string): Promise<ComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.editor.selection.getSelectedComponents(appData, token)
    )
  }

  /**
   * @doc Selection
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.selection.deselectComponents(token, {compsToDeselect: [componentRef]});
   * @param {string} token - app token, not in use
   * @param options -
   * - compsToDeselect: An array of the component references to deselect.
   * @description Deselects the specified components.
   * @returns A Promise that is resolved when the components are deselected.
   */
  function deselectComponents(
    token: string,
    options: {compsToDeselect: ComponentRef[]}
  ) {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.compsToDeselect,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.editor.selection.deselectComponents(appData, token, options)
    )
  }

  /**
   * @doc Selection
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.selection.selectComponentByCompRef(token, {compsToSelect: [componentRef]});
   * @param {string} token - app token, not in use
   * @param options -
   * - compsToSelect: An array of the component references to select.
   * @description Selects the specified components.
   * @returns A Promise that is resolved when components are selected.
   */
  function selectComponentByCompRef(
    token: string,
    options: {compsToSelect: ComponentRef[]}
  ) {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.compsToSelect,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.editor.selection.selectComponentByCompRef(appData, token, options)
    )
  }

  /**
   * @doc Selection
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.selection.locateAndHighlightComponentByCompRef(token, {compRef: componentRef});
   * @param {string} token - app token, not in use
   * @param options -
   * - compRef: Component that to get user attention by highlighting it and moving it to the viewport.
   * @description This method should be used when an application wants to bring user attention to a component by highlighting it.
   * After invocation, the Editor scrolls to the component to bring it to the viewport, and highlights it.
   * Only one component can be highlighted at a time. Each invocation clears the highlights before starting.
   * If called multiple times you need to call `clearHighlights` only on the last component.
   * @returns A promise that is resolved when the page is located and the component is highlighted.
   */
  function locateAndHighlightComponentByCompRef(
    token: string,
    options: {compRef: ComponentRef}
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.selection.locateAndHighlightComponentByCompRef(
        appData,
        token,
        options
      )
    )
  }

  /**
   * @doc Selection
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.selection.locateAndHighlightComponentByCompRef(token, {compRef: componentRef});
   * editorSDK.editor.selection.clearHighlights(token);
   * @param {string} token - app token, not in use
   * @description Removes highlighting from the last component that was selected with `locateAndHighlightComponentByCompRef`.
   * @returns A promise that is resolved when the highlight is cleared.
   */
  function clearHighlights(token: string): Promise<void> {
    return getAPI().then((api) => api.editor.selection.clearHighlights())
  }
  return {
    getSelectedComponents,
    deselectComponents,
    selectComponentByCompRef,
    locateAndHighlightComponentByCompRef,
    clearHighlights,
  }
}
