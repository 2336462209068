import apiWrapper from '../../privates/apiWrapper'

type ThemeFont = {
  style: string
  variant: string
  weight: string
  size: string
  lineHeight: string
  family: string
  color: string
  bold: boolean
  italic: boolean
  fontWithFallbacks: string
  cssColor: string
}

export default function (appData) {
  /**
   * @doc Theme.Fonts
   * @description Returns an object containing indexed font options for the site.
   * @example const fonts = await editorSDK.document.theme.fonts.getMap('token')
   * @param token - app token - not in use
   * @returns A promise that provides an object containing indexed font options for the site - keys from `font_0` to `font_10` and values representing the fonts.
   * - `style` - string
   * - `variant` - string
   * - `weight` - string
   * - `size` - string
   * - `lineHeight` - string
   * - `family` - string
   * - `color` - string
   * - `bold` - boolean
   * - `italic` - boolean
   * - `fontWithFallbacks` - string
   * - `cssColor` - string
   */
  function getMap(token: string): Promise<Record<string, ThemeFont>> {
    return apiWrapper.dsGetter(
      {waitingType: apiWrapper.WAITING_TYPES.NONE},
      (api) => api.document.theme.fonts.getMap(appData, token)
    )
  }

  return {
    getMap,
  }
}
