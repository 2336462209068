import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc SiteMembers
   * @description Get the custom 'no permissions' pageId (a page that is defined in the editor to be shown when user of user navigates to a restricted page.
   * @example const pageId = await editorSDK.document.siteMembers.getCustomNoPermissionsPageId('token')
   * @param token - app token - not in use
   * @returns a `Promise` that is resolved with the page id (null if theres none)
   */
  function getCustomNoPermissionsPageId(token): Promise<string | null> {
    return getAPI().then((api) => {
      return api.document.siteMembers.getCustomNoPermissionsPageId(appData)
    })
  }

  /**
   * @doc SiteMembers
   * @description Set the custom 'no permissions' pageId
   * @example await editorSDK.document.siteMembers.setCustomNoPermissionsPageId('token', {pageId: 'pageId'})
   * @param token - app token - not in use
   * @param options -
   *  - pageId: page id to set as the custom no permissions page.
   * @returns a `Promise` that is resolved on page changed
   */
  function setCustomNoPermissionsPageId(
    token,
    options: {pageId: string}
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.siteMembers.setCustomNoPermissionsPageId(
        appData,
        token,
        options.pageId
      )
    })
  }

  return {
    getCustomNoPermissionsPageId,
    setCustomNoPermissionsPageId,
  }
}
