import {getAPIWithPlatformContext} from '../../privates/editorAPI'
import {
  PageRef,
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  TPAComponentTypeStrings,
  AppData,
} from '@wix/editor-platform-sdk-types'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData
) {
  /**
   * @doc Add
   * @deprecated please use `editorSDK.document.application.add()`
   * @note `Classic Editor` `Editor X`
   * @example
   * const {instanceId} = await editorSDK.document.tpa.add.application(token, {
   *   appDefinitionId
   * });
   * @param token - app token - not in use
   * @param options -
   * - appDefinitionId: The appDefinitionId of the app to add.
   * - managingAppDefId: Optional. The appDefinitionId of the app that can manage the pages added by this function. For example, the ID of *Members*, which can manage other apps' pages.
   * @description Adds a new TPA to the site.
   * @returns The `instanceId` of the new app.
   * If the app has a section page, also returns a reference to the page, its pageUriSEO and its title.
   */
  function application(
    token,
    options: {
      showPageAddedPanel?: boolean
      disableAddPanel?: boolean
      shouldNavigate?: boolean
      appDefinitionId: string
      managingAppDefId?: string
      isSilent?: boolean
    }
  ): Promise<
    | {instanceId: string}
    | {
        instanceId: string
        pageRef: PageRef
        pageUriSEO: string
        title: string
      }
  > {
    return getAPIWithPlatformContext().then(({api, platformContext}) => {
      if (platformContext.isSilent) {
        options.shouldNavigate = false
        options.disableAddPanel = true
        options.showPageAddedPanel = false
        options.isSilent = true
      }
      return api.document.tpa.add.application(appData, token, options)
    })
  }

  /**
   * @doc Add
   * @note `Classic Editor` `Editor X`
   * @example
   * await editorSDK.document.tpa.add.component(token, {
   *   componentType: editorSDK.document.tpa.TPAComponentType.Page, // OR componentType: 'PAGE',
   *   page: {
   *     pageId: 'product_page'
   *   }
   * });
   * @example
   * await editorSDK.document.tpa.add.component(token, {
   *  managingAppDefId: 'appDef',
   *  componentType: editorSDK.document.tpa.TPAComponentType.Widget,
   *  appDefinitionId: 'app-def-id',
   *  widget: {
   *    widgetId: 'product_widget',
   *  }
   * });
   * @param token - app token - not in use
   * @param options -
   *  - managingAppDefId: Optional. The appDefinitionId of the app that can manage the pages added by this function. For example, the ID of *Members*, which can manage other apps' pages.
   *  - componentType: The TPA component type - Page or Widget.
   *  - appDefinitionId - The unique ID of the TPA component's app. Required only in Editor Extensions context.
   *   Uses its own appDefinitionId if not specified.
   *  - widget
   *  - widget.widgetId: The TPA widget's ID, as defined in the Dev Center.
   *  - widget.allPages: When set to *true* specifies that the widget should be shown on all pages (default is *false*).
   *  - widget.wixPageId: The ID of the page to add the widget to (current page if not specified).
   *  - widget.shouldNavigate: When set to *true* navigate to the page with the specified wixPageId (default is *true*). When the Editor is in silent-install mode `shouldNavigate` is set to *false*.
   *  - page.pageId: The TPA page ID, as defined in the Dev Center.
   *  - page.title: The new page's title.
   *  - page.isHidden: Whether the page is hidden from the menu (e.g. Order Confirmation page).
   *  - page.shouldNavigate: When set to *true* navigate to the target page (default is *true*). When the Editor is in silent-install mode `shouldNavigate` is set to *false*.
   * @description Adds a TPA section page or a TPA widget.
   * @returns A promise to data about the component added.
   */
  function component(
    token,
    options: ContextAwareOptions<
      Context,
      {
        managingAppDefId?: string
        componentType: TPAComponentTypeStrings
        appDefinitionId?: string
        copyStyle?: boolean
        styleId?: string
        widget?: {
          widgetId: string
          allPages?: boolean
          wixPageId?: string
          shouldNavigate?: boolean
        }
        page?: {
          pageId: string
          title?: string
          isHidden?: boolean
          shouldNavigate?: boolean
        }
      },
      {appDefinitionId: string}
    >
  ): Promise<
    | {compId: string}
    | {
        compId: string
        pageRef: PageRef
        pageUriSEO: string
        title: string
      }
  > {
    return getAPIWithPlatformContext().then(({api, platformContext}) => {
      if (platformContext.isSilent) {
        if (options?.widget) {
          options.widget.shouldNavigate = false
        }
        if (options?.page) {
          options.page.shouldNavigate = false
        }
      }
      return api.document.tpa.add.component(appData, token, options)
    })
  }

  return {
    application,
    component,
  }
}
