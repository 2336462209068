import {ColorPalette} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  /**
   * @doc Theme.Colors
   * @description Returns the color palette of the site. The color palette is an object containing 36 colors that are suitable for the site.
   * @example const colors = await editorSDK.document.theme.colors.getAll('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with all of the theme colors of the site. The returned object contains
   * - key: color name
   * - value: string describing color value
   */
  function getAll(token: string): Promise<ColorPalette> {
    return apiWrapper.dsGetter(
      {waitingType: apiWrapper.WAITING_TYPES.NONE},
      (api) => api.document.theme.colors.getAll(appData, token)
    )
  }

  return {
    getAll,
  }
}
