import Editor from './editor'
import Document from './document'
import Environment from './editor/environment'
import EditorInfo from './editor/info'
import DocumentInfo from './document/info'
import Selection from './editor/selection'
import EditorUtils from './editor/utils'
import Preview from './editor/preview'
import Site from './editor/userPreferences/site'
import Panel from './editor/panel'
import Ide from './editor/ide/ide'
import TPA from './document/tpa/tpa'
import Menu from './document/menu'
import Application from './document/application/application'
import Theme from './document/theme/theme'
import Mobile from './document/mobile/mobile'
import Pages from './document/pages/pages'
import Fonts from './document/fonts/fonts'
import Controllers from './document/controllers'
import History from './document/history'
import PagesGroup from './document/pagesGroup/pagesGroup'
import Language from './document/language'
import Vfs from './document/vfs'
import Components from './document/components/components'
import SiteSegments from './document/siteSegments'
import SiteMembers from './document/siteMembers'
import Routers from './document/routers/routers'
import AppBuilder from './extraNamespaces/appBuilder/appBuilder'
import Responsive from './extraNamespaces/responsive/responsive'
import AppSettings from './appSettings/appSettings'
import ResponsiveLayout from './document/responsiveLayout'
import Wixapps from './document/wixapps'
import DocumentAccessibility from './document/accessibility'
import EditorAccessibility from './editor/accessibility'
import {
  SDKDefaultContext,
  SDKContext,
  AppData,
  IExtraNamespaces,
} from '@wix/editor-platform-sdk-types'
import {SDKEventTarget} from './eventTarget'
import {_extraNamespacesFactory} from './extraNamespaces'

/** @hidden */
function getExtraNamespaceObj(
  appData,
  extraNamespaces: IExtraNamespaces[] = []
): Partial<ReturnType<typeof _extraNamespacesFactory>> {
  const EXTRA_NAMESPACES = {
    appBuilder: AppBuilder(appData),
    responsive: Responsive(appData),
  }

  // pick namespaces
  return extraNamespaces.reduce((obj, nameSpaceKey) => {
    if (EXTRA_NAMESPACES.hasOwnProperty(nameSpaceKey)) {
      obj[nameSpaceKey] = EXTRA_NAMESPACES[nameSpaceKey]
    }
    return obj
  }, {})
}

export default function editorSDK<
  Context extends SDKContext = SDKDefaultContext
>(appData: AppData, extraNamespaces?: IExtraNamespaces[]) {
  const {
    addEventListener,
    removeEventListener,
    __dispatchEvent,
    removeAllListeners,
  } = SDKEventTarget(appData)

  // **********************************   IMPORTANT    ****************************************************
  //
  // Do not add any new namespaces here. All new namespaces should be exposed in the Editor or Document namespace.
  //
  // ******************************************************************************************************
  return Object.assign(
    {
      editor: Editor<Context>(appData),
      document: Document<Context>(appData),
      appSettings: AppSettings<Context>(appData),

      addEventListener,
      removeEventListener,
      /**
       * @hidden
       * used in `santa-platform-apps-container` to clear event listeners when application is 'hot' reloaded
       */
      removeAllListeners,
      /**
       * @hidden
       * used in `santa-platform-apps-container` and `js/api/events/propagation.ts` to trigger/dispatch events
       */
      __dispatchEvent,

      /**
       * Editor API functions
       */
      preview: Preview(appData),
      selection: Selection(appData),
      panel: Panel(appData),
      environment: Environment(appData),
      ide: Ide(appData),
      utils: EditorUtils(appData),
      userPreferences: {
        site: Site(appData),
      },

      /**
       * Document API functions
       */
      application: Application<Context>(appData),
      controllers: Controllers<Context>(appData),
      history: History(appData),
      language: Language(appData),
      menu: Menu<Context>(appData),
      pages: Pages<Context>(appData),
      pagesGroup: PagesGroup<Context>(appData),
      siteSegments: SiteSegments(appData),
      siteMembers: SiteMembers(appData),
      vfs: Vfs(appData),
      components: Components<Context>(appData),
      fonts: Fonts(appData),
      routers: Routers<Context>(appData),
      theme: Theme(appData),
      tpa: TPA<Context>(appData),
      mobile: Mobile(appData),
      info: Object.assign(EditorInfo(appData), DocumentInfo<Context>(appData)),
      responsiveLayout: ResponsiveLayout(appData),
      wixapps: Wixapps(appData),
      accessibility: Object.assign(
        EditorAccessibility(appData),
        DocumentAccessibility(appData)
      ),
    },
    getExtraNamespaceObj(appData, extraNamespaces)
  )
}
