import {
  ComponentRef,
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
  PageRef,
  Connection,
  TPAPublicDataScope,
} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../privates/apiWrapper'

type pageRefWithTPA = {pageRef?: PageRef; includeTPAWidget?: boolean}

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData
) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc Controllers
   * @example
   * const controllersInPage = await editorSDK.document.controllers.listControllers(token, {pageRef, includeTPAWidget: true});
   * @param token - app token - not in use
   * @param options -
   * - pageRef: The page to query for controllers.
   * - appDefinitionId (Required only in Editor Extensions context): The unique ID of the application whose controllers you want to get.
   * - includeTPAWidget: Boolean that when set to *true* includes OOI controllers in the list of controllers. By default is *undefined*, and they are not included.
   * @description Returns an array of the app's [controllers](../articles/controllers.md) on a specified page. If no page is specified, returns all of the controllers on the site.
   * @returns A Promise that is resolved with an array of all controllers on the specified page, or all of the controllers on the site, if no page is specified.
   */
  function listControllers(
    token,
    options: ContextAwareOptions<
      Context,
      pageRefWithTPA | void,
      {appDefinitionId: string}
    >
  ): Promise<Array<{controllerRef: ComponentRef}>> {
    const pageRef = options ? (options as pageRefWithTPA).pageRef : null

    return apiWrapper.dsGetter(
      {
        compRefsToAwait: pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.controllers.listControllers(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @example
   * const controllersInSite = await editorSDK.document.controllers.listAllControllers('token');
   * @param token - app token - not in use
   * @param options (Required only in Editor Extensions context):
   * - appDefinitionId: The unique ID of the application whose controllers you want to get.
   * - includeTPAWidget: Boolean that when set to *true* includes OOI controllers in the list of controllers. By default is *undefined*, and they are not included.
   * @description Returns an array of the app's [controllers](../articles/controllers.md) on the site. To get a list of site controllers that a component is allowed to connect to, use [listConnectableControllers](#listConnectableControllers).
   * @returns A Promise that is resolved with an array of all of the controllers on the site.
   */
  function listAllControllers(
    token: string,
    options: ContextAwareOptions<
      Context,
      {includeTPAWidget?: boolean} | void,
      {appDefinitionId: string}
    >
  ): Promise<Array<{controllerRef: ComponentRef}>> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) =>
        api.document.controllers.listAllControllers(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @example
   * const connectableControllers = await editorSDK.document.controllers.listConnectableControllers(token, {componentRef});
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A reference to the component to connect.
   * - appDefinitionId Required only in Editor Extensions context): The unique ID of the application whose controllers you want to get.
   * - includeTPAWidget: Boolean that when set to *true* includes OOI controllers in the list of controllers. By default is *undefined*, and they are not included.
   * @description Returns a list of all [controllers](../articles/controllers.md) that the specified component ref can connect to. When a controller is displayed on multiple pages, for example in header and footer in Classic Editor, this returns the controller on the header / footer. In EditorX, controllers which are part of a shared block will not be returned &mdash; Learn more in [Shared Blocks](../articles/byref-components.md#components-in-shared-blocks). Contact the [Editor team](https://wix.slack.com/messages/C4KPAQ33K) before implementing a case where you connect a component from a page to a controller in the master page.
   * @returns A promise to the array of controllers in the same page as the component.
   */
  function listConnectableControllers(
    token,
    options: ContextAwareOptions<
      Context,
      {componentRef: ComponentRef; includeTPAWidget?: boolean},
      {appDefinitionId: string}
    >
  ): Promise<Array<{controllerRef: ComponentRef}>> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) =>
        api.document.controllers.listConnectableControllers(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Controllers
   * @example const connectionData = await editorSDK.document.controllers.connect(token, {
   *   connectToRef: componentRef,
   *   controllerRef,
   *   role: 'my-image',
   *   connectionConfig: {isRegistered: false},
   *   isPrimary: true,
   *   subRole: 'my-button',
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.connectToRef - The component to connect.
   * @param options.controllerRef - The controller to connect to the component.
   * @param options.role - The role of the component, according to the controller.
   * @param options.connectionConfig - Optional additional configuration for the connection.
   * @param options.isPrimary - Boolean that, if set to *true*, indicates that the connection is primary (default is *false*).
   * @param options.subRole - A secondary component role. Use to override specific behaviors defined in the app manifest for the *role*. All other behaviors defined in the app manifest for the *role* will continue to apply to the *subRole*.
   * Primary connections may affect the component's stage behavior and GFPP actions via the app manifest.
   * @description Creates or updates a connection between a component and a controller, with the specified role and an optional second role. [Learn more](../articles/controllers.md).
   * @returns A promise that is resolved once the connection has been added.
   */
  function connect(
    token,
    options: {
      connectToRef: ComponentRef
      controllerRef: ComponentRef
      role: string
      connectionConfig?: object
      isPrimary?: boolean
      subRole?: string
    }
  ): Promise<{
    connectToRef: ComponentRef
    controllerRef: ComponentRef
    role: string
    subRole?: string
  }> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [options.connectToRef, options.controllerRef],
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.controllers.connect(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @example
   * await editorSDK.document.controllers.disconnect(token, {
   *   connectToRef: componentRef,
   *   controllerRef,
   *   role: 'not-needed-anymore'
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.connectToRef - The component side of the connection
   * @param options.controllerRef - The controller side of the connection
   * @param options.role - The role of the connection
   * @description Removes connections between the specified component and controller.
   * If a role is specified, removes only the connection with the specified role.
   * Otherwise, removes all connections between the component and the controller. [Learn more](../articles/controllers.md).
   * @returns A promise that is resolved when the connection is removed.
   */
  function disconnect(
    token,
    options: {
      connectToRef: ComponentRef
      controllerRef: ComponentRef
      role?: string
    }
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [options.connectToRef, options.controllerRef],
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.controllers.disconnect(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @example
   * const controllerData = await editorSDK.document.controllers.getData(token, {controllerRef});
   * @param token - app token - not in use
   * @param options -
   * - controllerRef: A reference to the controller.
   * - scope (APP | COMPONENT): The scope for TPA components.
   * @description Returns the relevant data of a specified [controller](../articles/controllers.md).
   * For OOI controllers, this function uses the [tpa.data.getAll](./TPA-PublicData.md#getall) method with the *COMPONENT* scope, and
   * the type field is resolved as the *widgetId* of the controller.
   * @returns A promise that resolves with the data of the specified controller.
   */
  function getData(
    token,
    options: {controllerRef: ComponentRef; scope?: TPAPublicDataScope}
  ): Promise<{type: string; config: any; displayName: string}> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.controllerRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.controllers.getData(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @example
   * const config = { k: 'val' }
   * await editorSDK.document.controllers.saveConfiguration(token, {
   *   controllerRef,
   *   config
   * });
   * @param token - app token - not in use
   * @param options -
   * - controllerRef: The controller reference.
   * - config: The configuration object.
   * - scope (APP | COMPONENT): The scope in which to store the data. *APP* scope is accessible through all components of the same TPA.
   *  Relevant only for TPAs.
   * @description Adds or replaces the configuration saved for the specified [controller](../articles/controllers.md).
   * For OOI controllers, saveConfiguration uses the [tpa.data.set](./TPA-PublicData.md#set) method,
   * which sets the TPA's public data entry (the data that the user added in the Editor).
   * Each key-value pair of config object is set in the TPA's public data *COMPONENT* scope.
   * @returns A promise that is resolved when the config object has been updated.
   */
  function saveConfiguration(
    token,
    options: {
      controllerRef: ComponentRef
      config: object
      scope?: TPAPublicDataScope
    }
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.controllerRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.controllers.saveConfiguration(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @deprecated
   * @description Deprecated. Use listControllerConnections instead.
   * @returns A promise resolved with the Controller's connections
   */
  function getControllerConnections(
    token,
    options: {controllerRef: ComponentRef}
  ): Promise<
    Array<{
      componentRef: ComponentRef
      connection: Connection
    }>
  > {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.controllerRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.controllers.listControllerConnections(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Controllers
   * @description Returns a list of connections of the given [controller](../articles/controllers.md).
   * @example
   * const connections = await editorSDK.document.controllers.listControllerConnections(token, {controllerRef});
   * @param token - app token - not in use
   * @param options -
   * @param options.controllerRef - a reference to the controller
   * @description Returns all the connections for a specified controller.
   * @returns A list of data for each of the connections
   */
  function listControllerConnections(
    token,
    options: {controllerRef: ComponentRef}
  ): Promise<
    Array<{
      componentRef: ComponentRef
      connection: Connection
    }>
  > {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.controllerRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.controllers.listControllerConnections(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Controllers
   * @example
   * const connections = await editorSDK.document.controllers.listConnections(token, {componentRef});
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - A reference to the component
   * @description Gets a list of all the connections of a specified component. [Learn more](../articles/controllers.md).
   * @returns An array of connections of the specified component.
   */
  function listConnections(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<
    Array<{
      type: 'ConnectionItem'
      role: string
      isPrimary: boolean
      controllerRef: ComponentRef
      config?: object
      subRole?: string
    }>
  > {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.controllers.listConnections(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @example
   * const compRefs = await editorSDK.document.controllers.listConnectedComponents(token, {controllerRef});
   * @param token - app token - not in use
   * @param options -
   * @param options.controllerRef - the controller ref
   * - controllerRef : the controller ref
   * @description Gets a list of all the components connected to a [controller](../articles/controllers.md).
   * @returns A promise to an array of all components connected to the controller.
   */
  function listConnectedComponents(
    token,
    options: {controllerRef: ComponentRef}
  ): Promise<ComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.controllerRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) =>
        api.document.controllers.listConnectedComponents(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Controllers
   * @example
   * await editorSDK.document.controllers.setDisplayName(token, {controllerRef, name: 'name'});
   * @param token - app token - not in use
   * @param options -
   * @param options.controllerRef - A reference to the controller
   * @param options.name - The new display name for the controller
   * @description Updates a component's display name.
   * @returns A promise that is resolved once the display name has been updated.
   */
  function setDisplayName(
    token,
    options: {controllerRef: ComponentRef; name: string}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.controllerRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.NONE,
      },
      (api) => api.document.controllers.setDisplayName(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @example
   * await editorSDK.document.controllers.setState(token, {
   *   state: {
   *      someState: [componentRef]
   *   }
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.state - A map of states to arrays of controller refs
   * @description Updates the state for one or more [controllers](../articles/controllers.md).
   * @returns A promise that is resolved once the state has been updated.
   */
  function setState(
    token,
    options: {
      state: {
        [index: string]: ComponentRef[]
      }
    }
  ): Promise<void> {
    const compRefsToAwait = Object.keys(options.state)
      .map((key) => options.state[key])
      .reduce((acc, val) => acc.concat(val), [])
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.controllers.setState(appData, token, options)
    )
  }

  /**
   * @doc Controllers
   * @example
   * const isControllerAlreadyExists = await editorSDK.document.controllers.isControllerExists(token, {
   *   controllerType: 'myFirstController'
   * });
   * @param token - App token - not in use
   * @param options -
   *  - controllerType: The type of controller to check the existence of.
   *  - pageRef: The page ref for which to check the existence of the controller. If it is not provided, the method checks the existence of the controller on the site.
   *  - appDefinitionId: The unique ID of the application (required only in Editor Extensions context).
   * @description Checks whether a [controller](../articles/controllers.md) already exists on the site or on the page (if pageRef is provided).
   * @returns A promise that is resolved with a Boolean value of *true* if the controller exists, *false* otherwise.
   */
  function isControllerExists(
    token,
    options: ContextAwareOptions<
      Context,
      {controllerType: string; pageRef?: PageRef},
      {appDefinitionId: string}
    >
  ): Promise<boolean> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      async (api) => {
        const controllersOfType = await api.document.controllers.findAllByType(
          appData,
          token,
          options
        )

        return controllersOfType.length > 0
      }
    )
  }

  /**
   * @doc Controllers
   * @example
   * const controllersOfType = await editorSDK.document.controllers.findAllByType(token, {
   *   controllerType: 'myFirstController'
   * });
   * @param token - App token - not in use
   * @param options -
   *  - controllerType: The type of the controller.
   *  - appDefinitionId: The unique ID of the application (required only in Editor Extensions context).
   * @description Finds all [controllers](../articles/controllers.md) of the provided type.
   * @returns A promise that is resolved with a list of controller refs of the provided type.
   */
  function findAllByType(
    token,
    options: ContextAwareOptions<
      Context,
      {controllerType: string},
      {appDefinitionId: string}
    >
  ): Promise<Array<{controllerRef: ComponentRef}>> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.controllers.findAllByType(appData, token, options)
    )
  }

  return {
    listControllers,
    listConnectableControllers,
    connect,
    disconnect,
    getData,
    saveConfiguration,
    getControllerConnections,
    listControllerConnections,
    listConnections,
    listConnectedComponents,
    setDisplayName,
    setState,
    listAllControllers,
    isControllerExists,
    findAllByType,
  }
}
