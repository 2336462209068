import {getAPI} from '../../privates/editorAPI'
import {REGISTER_ERROR} from '../../../utils/consts'
import {ErrorInfo} from '@wix/editor-platform-sdk-types'

import * as pmrpc from 'pm-rpc'

export default function (appData) {
  /**
   * @description Registers an app to listen for errors. This function gets a callback that is invoked when errors
   * occur in SDK functions. An app only has to register once for all SDK errors. [Learn more](../articles/error-handling-async-operations.md).
   * @doc Errors
   * @example
      await editorSDK.document.errors.registerToErrors('token', {callback: ({error, methodName}) => {}});
   * @param token - app token - not in use
   * @param options
   * - `callback`: A function that is invoked when an error occurs.
   * @returns a promise
   */
  function registerToErrors(
    token,
    options: {callback: (arg1: ErrorInfo) => void}
  ): Promise<void> {
    return getAPI().then((api) => {
      const apiName = REGISTER_ERROR + appData.appDefinitionId

      pmrpc.api.set(apiName, options)
      return api.document.errors.registerToErrorThrownForContext(appData)
    })
  }
  /**
   * @description Unregisters an app from listening for errors. This function is only relevant when `registerToErrors` was called before. [Learn more](../articles/error-handling-async-operations.md).
   * @doc Errors
   * @example
     await editorSDK.document.errors.unRegisterToErrors('token');
   * @param token - app token - not in use
   * @returns a promise
   */
  function unRegisterToErrors(token): Promise<void> {
    return getAPI().then((api) => {
      const apiName = REGISTER_ERROR + appData.appDefinitionId

      pmrpc.api.unset(apiName)
      api.document.errors.unRegisterToErrorThrownForContext(appData, token)
    })
  }

  return {
    registerToErrors,
    unRegisterToErrors,
  }
}
