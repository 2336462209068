import {getAPI} from '../privates/editorAPI'
import {PageRef} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /*used for old blog migration - when converting blog app page to regular page - will be removed once blog migration is done*/
  function convertAppPage(
    token: string,
    options: {pageRef: PageRef}
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.wixapps.migrate.convertAppPage(
        appData,
        token,
        options
      )
    })
  }
  return {
    convertAppPage,
  }
}
