let originAppDefId = null
import {getAPI} from '../privates/editorAPI'

const verifyApplicationOrigin = (appDefId) => appDefId === originAppDefId

function openProgressBar(appData, token, options) {
  return new Promise((resolve, reject) => {
    if (originAppDefId === appData.appDefinitionId) {
      return reject(
        new Error('Progress bar panel was already opened by this app')
      )
    }
    if (originAppDefId !== null) {
      return reject(
        new Error('Progress bar panel was already opened by an other app')
      )
    }
    originAppDefId = appData.appDefinitionId
    return getAPI()
      .then((api) => api.editor.openProgressBar(appData, token, options))
      .then(resolve)
  })
}

function updateProgressBar(appData, token, options) {
  return new Promise((resolve, reject) => {
    if (originAppDefId === null) {
      return reject(new Error('Progress bar panel is not open'))
    }
    if (!verifyApplicationOrigin(appData.appDefinitionId)) {
      return reject(
        new Error(
          'Progress bar panel can be updated only by the app that opened it'
        )
      )
    }
    return getAPI()
      .then((api) => api.editor.updateProgressBar(appData, token, options))
      .then(resolve)
  })
}

function closeProgressBar(appData, token, options) {
  return new Promise((resolve, reject) => {
    if (originAppDefId === null) {
      return reject(new Error('Progress bar panel is not open'))
    }
    if (!verifyApplicationOrigin(appData.appDefinitionId)) {
      return reject(
        new Error(
          'Progress bar panel can be closed only by the app that opened it'
        )
      )
    }
    return getAPI()
      .then((api) => api.editor.closeProgressBar(appData, token, options))
      .then(() => {
        originAppDefId = null
        resolve()
      })
  })
}

export default {
  openProgressBar,
  updateProgressBar,
  closeProgressBar,
}
