let operationsQueue = []
let waitForChangesApplied
let handle = 0
const OPERATION_TYPES = {
  ALL: 'all',
  COMP: 'component',
  ROUTER: 'router',
  MENU: 'menu',
}

const WAITING_TYPES = {
  COMPS_AND_TYPE: 'compsAndType',
  COMPS: 'comps',
  TYPE: 'type',
  NONE: 'none',
}

function setWaitChangesApplied(_waitForChangesApplied) {
  waitForChangesApplied = _waitForChangesApplied
}

function getCompOperations(compRefsToAwait) {
  const compRefs = Array.isArray(compRefsToAwait)
    ? compRefsToAwait
    : [compRefsToAwait]
  if (compRefs) {
    const componentIdsArray = compRefs.map((compRef) => compRef.id)
    return operationsQueue.filter((operation) =>
      componentIdsArray.includes(operation.id)
    )
  }

  return []
}

function includesAtLeastOneType(operationTypes, types) {
  return types.some((type) => operationTypes.includes(type))
}

function getTypesOperations(operationTypes) {
  const types = Array.isArray(operationTypes)
    ? operationTypes
    : [operationTypes]
  return types.includes(operationTypes.ALL)
    ? operationsQueue
    : operationsQueue.filter((operationItem) =>
        includesAtLeastOneType(operationItem.operationTypes, types)
      )
}

function removeOperations(operations) {
  const handles = new Set(operations.map((op) => op.handle))
  if (handles.size) {
    operationsQueue = operationsQueue.filter((op) => !handles.has(op.handle))
  }
}

function waitForOperations(operations) {
  return new Promise((resolve) => {
    if (operations.length) {
      waitForChangesApplied(() => {
        removeOperations(operations)
        resolve()
      })
    } else {
      resolve()
    }
  })
}

function waitForChanges(
  {compRefsToAwait, operationTypes, waitingType},
  callback
) {
  return new Promise((resolve) => {
    switch (waitingType) {
      case WAITING_TYPES.COMPS:
        if (compRefsToAwait) {
          const compOperations = getCompOperations(compRefsToAwait)
          waitForOperations(compOperations).then(() => resolve(callback()))
        } else {
          console.error('missing params for editorSDK API (comp refs)')
          resolve(callback())
        }
        break
      case WAITING_TYPES.TYPE:
        if (operationTypes) {
          const typeOperations = getTypesOperations(operationTypes)
          waitForOperations(typeOperations).then(() => resolve(callback()))
        } else {
          console.error('missing params for editorSDK API (comp types)')
          resolve(callback())
        }
        break
      case WAITING_TYPES.COMPS_AND_TYPE:
        const compOperations = getCompOperations(compRefsToAwait)
        const typeOperations = getTypesOperations(operationTypes)
        waitForOperations([...compOperations, ...typeOperations]).then(() =>
          resolve(callback())
        )
        break
      default:
        resolve(callback())
        break
    }
  })
}

function addOperation(compRef, operationTypes) {
  handle++
  const types = Array.isArray(operationTypes)
    ? operationTypes
    : [operationTypes]
  const id = compRef.id ? compRef.id : compRef
  operationsQueue.push({
    id,
    operationTypes: types,
    handle,
  })
}

function waitForAllChanges() {
  return waitForOperations(operationsQueue)
}

function clearQueue() {
  operationsQueue = []
}

export default {
  setWaitChangesApplied,
  waitForChanges,
  addOperation,
  OPERATION_TYPES,
  WAITING_TYPES,
  waitForAllChanges,
  clearQueue,
}
