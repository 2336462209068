import apiWrapper from '../privates/apiWrapper'
import {
  ComponentRef,
  LanguageCode,
  LanguageDefinition,
  LanguagesInitPayload,
} from '@wix/editor-platform-sdk-types'
import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @description Adds a language to a site for which *multilingual* functionality is enabled.
   * @doc Language
   * @note `Classic Editor`
   * @example
     await editorSDK.language.add('token', {languageCode: 'he'});
   * @param token - app token - not in use
   * @param options
   * - languageCode: The code of the language that is being added.
   * @returns A promise that is resolved when the language is added.
   */
  function add(token, options: {languageCode: LanguageCode}) {
    return getAPI().then((api) => {
      return api.document.language.add(appData, token, options)
    })
  }

  /**
   * @description Deprecated. Instead, use approach described in [Adding translations to your app](../articles/translation.md). Returns the translations of the given componentRef.
   * @doc Language
   * @note `Deprecated`
   * @example
     const translations = await editorSDK.language.component.getTranslations('token', {componentRef});
   * @param token - app token - not in use
   * @param options
   * - componentRef: A reference to the component.
   * @returns A promise that is resolved with the component's translations.
   */
  function getTranslations(token, options: {componentRef: ComponentRef}) {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: apiWrapper.OPERATION_TYPES.COMP,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.language.component.getTranslations(appData, token, options)
    )
  }

  /**
   * @description Returns *true* if the multilingual functionality is enabled.
   * @doc Language
   * @note `Classic Editor`
   * @example
     const isEnabled = await editorSDK.language.multilingual.isEnabled('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with a Boolean whose value is *true* if the multilingual functionality is enabled.
   */
  function isMultilingualEnabled(token) {
    return getAPI().then((api) => {
      return api.document.language.multilingual.isEnabled(appData)
    })
  }

  /**
   * @description Triggers auto translation on multilingual sites.
   * @doc Language
   * @note `Classic Editor`
   * @example
     await editorSDK.language.autoTranslate('token', {languageCode: 'he', origin: 'myApp'});
   * @param token - app token - not in use
   * @param options -
   * - languageCode: Optional. The code of the language to translate. Default is the current language.
   * - origin: Optional. What entity triggered the method, such as a specific app or an Editor.
   * @returns A promise that is resolved once the auto translation is done.
   */
  function autoTranslate(
    token: string,
    options?: {languageCode?: LanguageCode; origin?: string}
  ) {
    const {languageCode, origin} = options || {}
    return getAPI().then((api) =>
      api.document.language.autoTranslate({languageCode, origin})
    )
  }

  /**
   * @description Gets the original language that was set by the user. Only applies when the multilingual functionality is enabled.
   * @doc Language
   * @note `Classic Editor`
   * @example
     await editorSDK.language.original.get('token');
   * @param {string} token - app token, not in use
   * @returns {Promise<LanguageDefinition>} A promise that is resolved with the original [language object](../articles/glossary.md#Language).
   */
  function getOriginal(token: string): Promise<LanguageDefinition> {
    return getAPI().then((api) => api.document.language.original.get())
  }

  /**
   * @description Gets a list of the translation languages that were set by the user for a multilingual site. Each language is defined by a [language object](../articles/glossary.md#Language).
   * @doc Language
   * @note `Classic Editor`
   * @example
     await editorSDK.language.getTranslationLanguages('token');
   * @param {string} token - app token, not in use
   * @returns {Promise<LanguageDefinition[]>} A promise that is resolved with the list of the translation languages.
   */
  function getTranslationLanguages(
    token: string
  ): Promise<LanguageDefinition[]> {
    return getAPI().then((api) =>
      api.document.language.getTranslationLanguages()
    )
  }

  /**
   * @description Sets the original language and the translation languages. Each language is defined by a [language object](../articles/glossary.md#Language).
   * @doc Language
   * @note `Classic Editor`
   * @example
     await editorSDK.language.setLanguages('token', {
       originalLanguage: language,
       translationLanguages: [language]
     });
   * @param {string} token - app token, not in use
   * @param {LanguagesInitPayload} languages - languages to set
   * @returns {Promise<void>} A promise that is resolved when the languages objects are updated.
   */
  function setLanguages(
    token: string,
    languages: LanguagesInitPayload
  ): Promise<void> {
    return getAPI().then((api) => api.document.language.setLanguages(languages))
  }

  /**
   * @description Removes a specific translation language from a multilingual site.
   * @doc Language
   * @note `Classic Editor`
   * @example
   *   await editorSDK.language.multilingual.languages.remove('token', {languageCode: 'he'});
   * @param token - app token - not in use
   * @param options -
   *    - languageCode: The code of the translation language to remove.
   * @returns {Promise<void>} A promise that is resolved when the translation language is removed.
   */
  function removeLanguage(
    token: string,
    options: {
      languageCode: LanguageCode
    }
  ): Promise<void> {
    return getAPI().then((api) =>
      api.document.language.multilingual.languages.remove(
        appData,
        token,
        options.languageCode
      )
    )
  }

  /**
   * @description Removes all translation languages from a multilingual site.
   * @doc Language
   * @note `Classic Editor`
   * @example
        await editorSDK.language.multilingual.languages.removeAll('token');
   * @param token - app token - not in use
   * @returns {Promise<void>} A promise that is resolved when the translation languages are removed.
   */
  function removeAllLanguages(token: string): Promise<void> {
    return getAPI().then((api) =>
      api.document.language.multilingual.languages.removeAll(appData, token)
    )
  }

  /**
   * @description Removes multilingual translation data from a component.
   * @doc Language
   * @note `Classic Editor`
   * @example
          await editorSDK.language.component.data.remove('token', {languageCode: 'he', componentRef});
   * @param token - app token - not in use
   * @param options -
   * - languageCode: Optional. The code of the translation language to remove.
   * - componentRef: A reference to the component from which multilingual translation data should be removed.
   * @returns {Promise<void>} A promise that is resolved when the translation data is removed from the component.
   */
  function componentDataRemove(
    token: string,
    options: {
      languageCode?: LanguageCode
      componentRef: ComponentRef
    }
  ): Promise<void> {
    return getAPI().then((api) =>
      api.document.language.component.data.remove(
        appData,
        token,
        options.languageCode,
        options.componentRef
      )
    )
  }

  return {
    add,
    component: {
      getTranslations,
      data: {
        remove: componentDataRemove,
      },
    },
    multilingual: {
      isEnabled: isMultilingualEnabled,
      languages: {
        remove: removeLanguage,
        removeAll: removeAllLanguages,
      },
    },
    current: CurrentNS(appData),
    original: {
      get: getOriginal,
    },
    setLanguages,
    getTranslationLanguages,
    autoTranslate,
  }
}

function CurrentNS(appData) {
  /**
   * @description Sets the current language of the site.
   * @doc Language
   * @note `Classic Editor`
   * @example
     await editorSDK.document.language.current.set('token', {languageCode: 'he'});
   * @param token - app token - not in use
   * @param options
   * - languageCode: The code of the language that is being set.
   * @returns A promise that is resolved when the language is set.
   */
  function set(token, options: {languageCode: LanguageCode}) {
    return getAPI().then((api) => {
      return api.document.language.current.set(appData, token, options)
    })
  }

  /**
   * @description Gets the current viewer language of the site
   * @doc Language
   * @note `Classic Editor` `Editor X`
   * @example
     await editorSDK.document.language.current.get('token');
   * @param token
   * @return A promise that is resolved either with current multilingual language or with null if multilingual is not enabled.
   */
  function get(token): Promise<string | null> {
    return getAPI().then((api) => {
      return api.document.language.current.get(appData, token)
    })
  }

  return {
    set,
    get,
  }
}
