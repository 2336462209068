import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Accessibility
   * @note `Classic Editor`
   * @description This method sets the site preferences advanced settings flag in the Editor. When set to *true*, Accessibility Manager advanced settings are on.
   * @example
   * editorSDK.editor.accessibility.setAdvancedSettings('token', true)
   * @param {string} token - app token, not in use
   * @param {boolean} value - The Boolean value to set for the flag
   * @returns A promise that is resolved when the flag is set.
   */
  function setAdvancedSettings(token, value: boolean): Promise<void> {
    return getAPI().then((api) =>
      api.editor.accessibility.setAdvancedSettings(appData, token, {value})
    )
  }

  /**
   * @doc Accessibility
   * @note `Classic Editor`
   * @description This method gets the site preferences advanced settings flag from the Editor. A value of *true* indicates that Accessibility Manager advanced settings are on.
   * @example
   * editorSDK.editor.accessibility.getAdvancedSettings('token')
   * @param {string} token - app token, not in use
   * @returns A promise that is resolved with the flag value.
   */
  function getAdvancedSettings(token): Promise<boolean> {
    return getAPI().then((api) =>
      api.editor.accessibility.getAdvancedSettings(appData, token)
    )
  }

  return {
    setAdvancedSettings,
    getAdvancedSettings,
  }
}
