import {
  PageGroupDataPointer,
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData
) {
  const pagesGroupOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc PagesGroup
   * @example
   * const pagesGroupId = await editorSDK.document.pagesGroup.create('token', {
   *    groupName: 'new_group_name'
   * })
   * @description This method creates pageGroup data item that is related to the application.
   * Page group data item holds list of pages. Each application is allowed to hold only one page group.
   * At the creation there are no pages in the pageGroup, use addPageToPagesGroup to add pages to the group.
   * @returns Promise that is resolved with pageGroup id
   * @param {string} token - app token, not in use
   * @param options
   * - groupName: name of the group
   * - applicationId (Required only in Editor Extensions context): An ID of the application to create a page group for.
   */
  function create(
    token: string,
    options: ContextAwareOptions<
      Context,
      {groupName: string},
      {applicationId: number}
    >
  ): Promise<string> {
    return apiWrapper.dsSetter(
      {
        operationTypes: pagesGroupOperationType,
        waitingType: apiWrapper.WAITING_TYPES.NONE,
      },
      (api) => api.document.pagesGroup.create(appData, token, options)
    )
  }

  /**
   * @doc PagesGroup
   * @note `Classic Editor` `Editor X`
   * @example
   * await editorSDK.document.pagesGroup.addPageToPagesGroup('token', {
   *    pagesGroupPointer: {
   *        type: 'data',
   *        id: 'id-of-page-group',
   *    },
   *    pageId: 'hn2tt'
   * })
   * @description This method adds a page to the page group.
   * Note: Page can only be contained within one page group.
   * @returns Promise that is resolved with undefined
   * @param {string} token - app token, not in use
   * @param options -
   * - pageId: page id. Note: page name should be given without #.
   * - pagesGroupPointer: pointer to the page group.
   */
  function addPageToPagesGroup(
    token: string,
    options: {pagesGroupPointer: PageGroupDataPointer; pageId: string}
  ): Promise<undefined> {
    const pageRef = {id: options.pageId}
    const pagesGroupRef = options.pagesGroupPointer
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [pageRef, pagesGroupRef],
        operationTypes: pagesGroupOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.pagesGroup.addPageToPagesGroup(appData, token, options)
    )
  }

  /**
   * @doc PagesGroup
   * @example
   * const pageGroupPointer = await editorSDK.document.pagesGroup.getById('token', {
   *    pagesGroupId: 'id-of-page-group'
   * })
   * @description This method returns the pointer to the pagesGroup with given id
   * @returns Promise that is resolved with pointer to the page group
   * @param {string} token - app token, not in use
   * @param options
   * - pagesGroupId: id of the pagesGroup
   */
  function getById(
    token: string,
    options: {pagesGroupId: string}
  ): Promise<PageGroupDataPointer> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: {id: options.pagesGroupId},
        operationTypes: pagesGroupOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.pagesGroup.getById(appData, token, options)
    )
  }

  /**
   * @doc PagesGroup
   * @example
   * const pageGroupPointer = await editorSDK.document.pagesGroup.getByGroupName('token', {
   *    groupName: 'new_group_name'
   * })
   * @description This method returns the pointer to the pagesGroup with given name
   * @returns Promise that is resolved with pointer to the page group
   * @param {string} token - app token, not in use
   * @param options
   * - groupName: name of the page group
   */
  function getByGroupName(
    token: string,
    options: {groupName: string}
  ): Promise<PageGroupDataPointer> {
    return apiWrapper.dsGetter(
      {
        operationTypes: pagesGroupOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.pagesGroup.getByGroupName(appData, token, options)
    )
  }

  /**
   * @doc PagesGroup
   * @example
   * const pageGroupsArr = await editorSDK.document.pagesGroup.getAll('token')
   * @description This method returns all the pages groups pointers that exist in the site
   * @param {string} token - app token, not in use
   * @param options (Required only in Editor Extensions context):
   * - applicationId: An ID of the application to get its pages groups.
   * @returns Promise that is resolved with array of pointers to all pages groups
   */
  function getAll(
    token: string,
    options: ContextAwareOptions<Context, void, {applicationId: number}>
  ): Promise<PageGroupDataPointer[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: pagesGroupOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.pagesGroup.getAll(appData, token, options)
    )
  }

  /**
   * @doc PagesGroup
   * @example
   * await editorSDK.document.pagesGroup.remove('token', {
   *    pagesGroupPointer: {
   *        type: 'data',
   *        id: 'id-of-page-group'
   *    }
   * })
   * @description This method permanently deletes the pageGroup
   * @returns Promise that is resolved with undefined
   * @param {string} token - app token, not in use
   * @param options
   * - pagesGroupPointer - pointer to the page group to delete
   */
  function remove(
    token: string,
    options: {pagesGroupPointer: PageGroupDataPointer}
  ): Promise<undefined> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.pagesGroupPointer,
        operationTypes: pagesGroupOperationType,
        waitingType: apiWrapper.WAITING_TYPES.NONE,
      },
      (api) => api.document.pagesGroup.remove(appData, token, options)
    )
  }

  return {
    create,
    addPageToPagesGroup,
    getById,
    getByGroupName,
    getAll,
    remove,
  }
}
