import {ComponentRef} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @description Get the style of the given component.
   * @note `Classic Editor` `Editor X`
   * @doc Style
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const style = await editorSDK.components.style.get('token', {componentRef: componentRef});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: The reference to the component.
   * @returns A promise that is resolved with the style of the given componentRef.
   */
  function get(token, options: {componentRef: ComponentRef}) {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.style.get(appData, token, options)
    )
  }

  /**
   * @description Update the object containing the given component's style properties.
   * @doc Style
   * @note `Classic Editor` `Editor X`
   * @example
     const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
     await editorSDK.components.style.update('token', {componentRef: componentRef, style: {componentClassName: 'wysiwyg.viewer.components.SiteButton'}});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: The reference to the component.
   * - style: An object containing the style properties to update.
   * @returns A promise that is resolved when the changes have been applied to the document.
   */
  function update(token, options: {componentRef: ComponentRef; style: any}) {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) => api.document.components.style.update(appData, token, options)
    )
  }

  /**
   * @description Update the whole style object of the given component.
   * @doc Style
   * @example
     const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
     await editorSDK.components.style.updateFull('token', {componentRef: componentRef, style: {skin: 'platform.components.skins.AppWidgetSkin'}});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: The reference to the component.
   * - style: An object containing the style properties to update.
   * @returns A promise that is resolved when the changes have been applied to the document.
   */
  function updateFull(
    token,
    options: {componentRef: ComponentRef; style: any}
  ) {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) => api.document.components.style.updateFull(appData, token, options)
    )
  }

  return {
    get,
    update,
    updateFull,
  }
}
