import {getAPI} from '../../privates/editorAPI'
import {SessionStateMap} from '@wix/editor-platform-sdk-types'

export type updateSessionStateOptions = {stateMap: SessionStateMap}
export default function (appData) {
  /**
   * @description Update the session state for several components.
   * Session state is not persisted, the state that you will set using this method will be valid only for the current editor session.
   * @doc Application
   * @example
     const compRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
     await editorSDK.application.sessionState.update('token', {
       stateMap: {[compRef.id]: 'stateName'}});
   * @param token - app token - not in use
   * @param options
   * - `stateMap`: A map of comp states, where the key is a component ID and the value is string that
   *    describes the editor-only session state of the component.
   * @returns a promise that is resolved when the state has been applied.
   */
  function updateSessionState(
    token: string,
    options: updateSessionStateOptions
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.application.sessionState.update(
        appData,
        token,
        options
      )
    })
  }

  return {
    update: updateSessionState,
  }
}
