import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @function listChildren
   * @doc Vfs
   * @example editorSDK.vfs.listChildren('token', {path: 'path'}).then(() => {});
   * @param {string} token - app token, not in use
   * @param options -
   * @returns - A promise that is resolved with the children
   */
  function listChildren(token, options: {path: any}) {
    return getAPI().then((api) => {
      return api.document.vfs.listChildren(appData, token, options)
    })
  }

  /**
   * @function readFile
   * @doc Vfs
   * @example
   * editorSDK.vfs.readFile('token', {path: 'path'}).then(() => {});
   * @param {string} token - app token, not in use
   * @param options -
   * @returns - A promise that is resolved with the file contents
   */
  function readFile(token, options: {path: any}) {
    return getAPI().then((api) => {
      return api.document.vfs.readFile(appData, token, options)
    })
  }

  /**
   * @function writeFile
   * @doc Vfs
   * @example editorSDK.vfs.writeFile('token', {path: 'path', content: 'content'}).then(() => {});
   * @param {string} token - app token, not in use
   * @param options -
   * @returns - A promise that is resolved upon completion
   */
  function writeFile(token, options: {path: any; content: any}) {
    return getAPI().then((api) => {
      return api.document.vfs.writeFile(appData, token, options)
    })
  }

  /**
   * @function deleteFile
   * @doc Vfs
   * @example editorSDK.vfs.deleteFile('token', {path: 'path', content: 'content'}).then(() => {});
   * @param {string} token - app token, not in use
   * @param options -
   * @returns - A promise that is resolved upon completion
   */
  function deleteFile(token, options: {path: any; content: any}) {
    return getAPI().then((api) => {
      return api.document.vfs.deleteFile(appData, token, options)
    })
  }

  /**
   * @function flush
   * @doc Vfs
   * @example editorSDK.vfs.flush('token').then(() => {});
   * @param {string} token - app token, not in use
   * @param options -
   * @returns - A promise that is resolved upon completion
   */
  function flush(token) {
    return getAPI().then((api) => {
      return api.document.vfs.flush(appData, token)
    })
  }

  /**
   * @doc Vfs
   * @param token - app token - not in use
   * @returns - A promise that is resolved with the viewer info
   */
  function getViewerInfo(token) {
    return getAPI().then((api) =>
      api.document.vfs.getViewerInfo(appData, token)
    )
  }

  return {
    listChildren,
    readFile,
    writeFile,
    deleteFile,
    flush,
    getViewerInfo,
  }
}
