import apiWrapper from '../../privates/apiWrapper'
import type {StyleRef} from '@wix/document-services-types'

export default function (appData) {
  /**
   * @doc Theme.Styles
   * @description Returns an object containing the indexed styles of the site.
   * @example const styles = await editorSDK.document.theme.styles.getAll(token)
   * //will return
   * //{
   * //     "txtNew": {
   * //         "type": "TopLevelStyle",
   * //         "id": "txtNew",
   * //         "style": {
   * //             "properties": {
   * //                 "f0": "font_0",
   * //                 "f1": "font_1",
   * //                 "f2": "font_2",
   * //                 "f3": "font_3",
   * //             },
   * //             "propertiesSource": {
   * //                 "f0": "theme",
   * //                 "f1": "theme",
   * //                 "f2": "theme",
   * //                 "f3": "theme",
   * //             },
   * //             "groups": {}
   * //         },
   * //         "componentClassName": "",
   * //         "pageId": "",
   * //         "compId": "",
   * //         "styleType": "system",
   * //         "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
   * //     },
   * //     "ap1": {
   * //         "type": "TopLevelStyle",
   * //         "id": "ap1",
   * //         "style": {
   * //             "properties": {
   * //                 "boxShadowToggleOn-shd": "false",
   * //                 "icon": "color_11",
   * //                 "iconh": "color_11",
   * //                 "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
   * //             },
   * //             "propertiesSource": {
   * //                 "icon": "theme",
   * //                 "iconh": "theme"
   * //             },
   * //             "groups": {}
   * //         },
   * //         "componentClassName": "wysiwyg.viewer.components.AudioPlayer",
   * //         "pageId": "",
   * //         "compId": "",
   * //         "styleType": "system",
   * //         "skin": "wysiwyg.viewer.skins.audioplayer.SimplePlayer"
   * //     }
   * // }
   * @param token - app token - not in use
   * @returns A promise that provides an object containing the indexed styles of the site.
   */
  function getAll(token: string): Promise<Record<string, StyleRef>> {
    return apiWrapper.dsGetter(
      {waitingType: apiWrapper.WAITING_TYPES.NONE},
      (api) => api.document.theme.styles.getAll(appData, token)
    )
  }

  return {
    getAll,
  }
}
