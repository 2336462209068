const API_TYPES = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  EDITOR: 'editor',
}

const isWorker = () =>
  typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope

const isMainFrame = () =>
  typeof window !== 'undefined' && window === window.parent

const getWindow = () => window

const getApiName = (appDefinitionId, apiType) =>
  `platform_${apiType}_${appDefinitionId}`

export {API_TYPES, isWorker, isMainFrame, getWindow, getApiName}
