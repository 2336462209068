import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Preview
   * @note `Classic Editor` `Editor X`
   * @description This method switches to preview mode and navigates to the given page.
   * If you're already on the right page this method just opens preview mode.
   * Works for static and dynamic pages.
   * @example
   * //navigation to dynamic page
   * editorSDK.editor.preview.navigateTo('token', {pageUrl: '/myRouterPrefix/Ash'})
   * //navigation to static page
   * editorSDK.editor.preview.navigateTo('token', {pageUrl: '/staticPageUriSEO'})
   * @param token - app token - not in use.
   * @param options
   * - pageUrl: URL of the page to navigate to.
   *   Static page URL format should be '/{pageURISEO}'.
   *   Dynamic page URL format should be '/{prefix}/{innerRoute}'.
   * @returns Promise that returns *undefined*.
   */
  function navigateTo(
    token: string,
    options: {pageUrl: string}
  ): Promise<undefined> {
    return getAPI().then((api) =>
      api.editor.preview.navigateTo(appData, token, options)
    )
  }

  return {
    navigateTo,
  }
}
