import {getAPI} from '../../privates/editorAPI'
import {
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
} from '@wix/editor-platform-sdk-types'

export type livePreviewRefreshOptions<
  Context extends SDKContext = SDKDefaultContext
> = ContextAwareOptions<
  Context,
  {shouldFetchData: boolean; source: string},
  {appDefinitionId: string}
>

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData
) {
  /**
   * @description Reloads a platform app in the Viewer. Whenever a platform app in the Editor changes its state, its viewer counterpart might get out of sync. This method makes the Viewer reload the app instead of using the cache. [Learn more](../articles/live-preview.md)
   * @doc LivePreview
   * @example editorSDK.application.livePreview.refresh(token, { shouldFetchData: false, source: 'AFTER_DB_CHANGE' });
   * @param {string} token - app token, not in use
   * @param options -
   * - source: The reason for the refresh, used for debugging. The value can contain anything that is meaningful to the app.
   * - shouldFetchData: Flag that is passed to createControllers and indicates if the refresh requires the Viewer app to re-fetch the app data.
   * - appDefinitionId (Required only in Editor Extensions context): The unique ID of the application to reload.
   * @returns - A promise
   */
  function refresh(token, options: livePreviewRefreshOptions<Context>) {
    return getAPI().then((api) => {
      return api.document.application.livePreview.refresh(
        appData,
        token,
        options
      )
    })
  }

  return {
    refresh,
  }
}
