import {
  AppEditorApi,
  EditorSDK,
  HandleActionFn,
} from '@wix/editor-platform-sdk-types'

export const getDefaultAppEditorApi = (): AppEditorApi => ({
  beforeComponentRemoved: (): void => undefined,
  canAddWidget: (): boolean => true,
  appInstalled: (): void => undefined,
  appUpdated: (): void => undefined,
  removeApp: (): void => undefined,
  removeAppCompleted: (): void => undefined,
  beforeRemoveApp: (): void => undefined,
  migrate: (): void => undefined,
  beforeWidgetAdded: (): void => undefined,
})

const handleActionFallbacks = new Set([
  'appInstalled',
  'appUpdated',
  'removeApp',
  'removeAppCompleted',
  'beforeRemoveApp',
  'migrate',
])

export const getFullAppEditorApi = (
  appEditorApi: Partial<AppEditorApi>,
  appDefinitionId: string,
  handleActionFn?: HandleActionFn,
  boundedSDK?: EditorSDK
): AppEditorApi => {
  const appApi: AppEditorApi = getDefaultAppEditorApi()
  Object.keys(appApi).forEach((key: string) => {
    const handleAction =
      handleActionFn && handleActionFallbacks.has(key)
        ? (payload) => handleActionFn({type: key, payload}, boundedSDK)
        : null
    const fn = appEditorApi[key] || handleAction || appApi[key]
    appApi[key] = (...args) => {
      try {
        return fn(...args)
      } catch (e) {
        const error = e?.message ? e : new Error()
        error.message = `Failed executing ${key} for app ${appDefinitionId}: ${
          e?.message || e
        }`
        throw error
      }
    }
  })
  return appApi
}
