import {getAPI} from '../../privates/editorAPI'
import {ComponentRef, BehaviorData} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @description Get the runtime state of the given componentRef.
   * @doc Behaviors
   * @example
   * const slideshowRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const runtimeState = await editorSDK.components.behaviors.getRuntimeState('token', {componentRef: componentRef});
   * @param token - app token - not in use
   * @param options -
   * - `componentRef`: the component ref.
   * @returns a promise that is resolved with the runtime state of the given componentRef.
   */
  function getRuntimeState(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<object> {
    return getAPI().then((api) => {
      return api.document.components.behaviors.getRuntimeState(
        appData,
        token,
        options
      )
    })
  }

  /**
   * @description Execute a runtime behavior the given componentRef.
   * @doc Behaviors
   * @example
     const slideshowRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
     await editorSDK.components.behaviors.execute('token', {componentRef: slideshowRef, behaviorName: 'moveToSlide', behaviorParams: {slide: 1}});
   * @param token - app token - not in use
   * @param options
   * - `componentRef`: the component ref.
   * - `behaviorName`: the name of the behavior to uodate.
   * - `behaviorParams`: an object containing behavior-specific parameters.
   * @returns a promise that is resolved when the behavior has been executed.
   */
  function execute(
    token,
    options: {
      componentRef: ComponentRef
      behaviorName: string
      behaviorParams: object
    }
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.components.behaviors.execute(appData, token, options)
    })
  }

  /**
   * @description Gets the behaviors of a given component.
   * @doc Behaviors
   * @example
   const compRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   const compBehaviors = await editorSDK.components.behaviors.get('token', {componentRef: compRef});
   * @param token - app token - not in use
   * @param options
   * - componentRef: A reference to the component.
   * @returns A promise that is resolved with all of behaviors stored on the component, returned as an array of objects.
   */
  function get(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<object[]> {
    return getAPI().then((api) => {
      return api.document.components.behaviors.get(appData, token, options)
    })
  }

  /**
   * @description Sets the behavior for a given component.
   * @doc Behaviors
   * @example
   const compRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   await editorSDK.components.behaviors.update('token', {componentRef: compRef, behavior: {
      action: 'screenIn',
      delay: 0,
      duration: 1.2,
      name: 'FadeIn',
      params: {},
      targetId: '',
      viewMode: 'DESKTOP'
   }});
   * @param token - app token - not in use
   * @param options
   * - componentRef: A reference to the component.
   * - behavior: An object defining the behavior.
   * @returns A promise that is resolved when the behavior is updated.
   */
  function update(
    token,
    options: {componentRef: ComponentRef; behavior: BehaviorData}
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.components.behaviors.update(appData, token, options)
    })
  }

  /**
   * @description Removes a named behavior of a given component.
   * @doc Behaviors
   * @example
   const compRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   await editorSDK.components.behaviors.remove('token', {componentRef: compRef, behaviorName: 'behaviorName', actionName: 'actionName'});
   * @param token - app token - not in use
   * @param options
   * - componentRef: A reference to the component
   * - behaviorName: The name of the behavior to remove. <i>(Tip: If behaviorName is not set this removes all behaviors of the specified actionName)</i>.
   * - actionName: The name of the action the behavior belongs to, such as *FadeIn*.
   * @returns A promise that is resolved when the behavior is removed.
   */
  function remove(
    token,
    options: {
      componentRef: ComponentRef
      behaviorName?: string
      actionName: string
    }
  ): Promise<object[]> {
    return getAPI().then((api) => {
      return api.document.components.behaviors.remove(appData, token, options)
    })
  }

  return {
    getRuntimeState,
    execute,
    get,
    update,
    remove,
  }
}
