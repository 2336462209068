import {getAPI} from '../../privates/editorAPI'

export default function (appData) {
  /**
   * @doc AppBuilder
   * @note `Blocks`
   * @example
   * const appName = await editorSDK.appBuilder.getAppName()
   * @param token - app token - not in use
   * @description Returns the appName as defined in Dev Center.
   * This function is available only in the Wix Blocks editor.
   * @returns {Promise} A promise that is resolved with the appName as a string value.
   */
  function getAppName(token?: string): Promise<string> {
    return getAPI().then((api) => {
      return api.appBuilder.getAppName()
    })
  }

  /**
   * @doc AppBuilder
   * @note `Blocks`
   * @example
   * const appNamespace = await editorSDK.appBuilder.getAppNamespace()
   * @param token - app token - not in use
   * @description Returns the appNamespace, a unique namespace identifier of the created app.
   * This function is available only in the Wix Blocks editor.
   * @returns {Promise} A promise that is resolved with the appNamespace as a string value.
   */
  function getAppNamespace(token?: string): Promise<string> {
    return getAPI().then((api) => {
      return api.appBuilder.getAppNamespace()
    })
  }

  return {
    getAppName,
    getAppNamespace,
  }
}
