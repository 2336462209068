import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc editorRouters
   * @note `Classic Editor` `Editor X`
   * @description This method refreshes the inner routes of the router for an active page.
   * If the active page does not belong to the calling application, the method does nothing.
   * This method works only for dynamic pages. Read more about Routers [here](../articles/routers.md).
   * @example
   * editorSDK.editor.routers.refresh('token')
   * @param token - app token - not in use.
   * @param options
   * selectedRoute: route to navigate after refresh.
   * @returns Promise that returns undefined.
   */
  function refresh(
    token: string,
    options?: {selectedRoute?: string}
  ): Promise<undefined> {
    return getAPI().then((api) =>
      api.editor.routers.refresh(appData, token, options)
    )
  }

  return {
    refresh,
  }
}
