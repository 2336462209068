import EventTarget from '@ungap/event-target'

import {
  AppData,
  SDKExtensionContext,
  SDKDefaultContext,
  EventHandler,
  EventTypeStrings,
  EventPayload,
} from '@wix/editor-platform-sdk-types'

import {getAPI} from './privates/editorAPI'

/** @hidden */
const applicationIdEventTargetMap: Record<number, EventTarget> = {}

/** @hidden */
function getEventTargetForApplication(
  appData: AppData & SDKDefaultContext
): EventTarget {
  if (!appData.applicationId) {
    throw new Error(
      'editorSDK needs to be bound to `applicationId` to handle events'
    )
  }

  if (!applicationIdEventTargetMap[appData.applicationId]) {
    const eventTarget = new EventTarget()
    applicationIdEventTargetMap[appData.applicationId] = eventTarget
  }

  return applicationIdEventTargetMap[appData.applicationId]
}

/** @hidden */
const extenstionNameEventTargetMap: Record<number, EventTarget> = {}

/** @hidden */
function getEventTargetForExtension(
  appData: AppData & SDKExtensionContext
): EventTarget {
  const extenstionName = appData.appDefinitionId
  if (!extenstionName) {
    throw new Error(
      'editorSDK needs to be bound to `extenstionName` / `appDefinitionId` to handle events'
    )
  }

  if (!extenstionNameEventTargetMap[extenstionName]) {
    const eventTarget = new EventTarget()
    extenstionNameEventTargetMap[extenstionName] = eventTarget
  }

  return extenstionNameEventTargetMap[extenstionName]
}

/** @hidden */
let panelEventTarget: EventTarget

/** @hidden */
function getEventTargetForPanel(): EventTarget {
  if (panelEventTarget) {
    return panelEventTarget
  }

  panelEventTarget = new EventTarget()
  return panelEventTarget
}

/** @hidden */
function isInDefaultContext(
  appData: AppData
): appData is AppData & SDKDefaultContext {
  return appData.context === 'default'
}

/** @hidden */
function isInExtensionContext(
  appData: AppData
): appData is AppData & SDKExtensionContext {
  return appData.context === 'extension'
}

/** @hidden */
function getEventTargetForCurrentContext(appData: AppData): EventTarget {
  if (isInExtensionContext(appData)) {
    return getEventTargetForExtension(appData)
  }

  if (isInDefaultContext(appData)) {
    return getEventTargetForApplication(appData)
  }

  return getEventTargetForPanel()
}

export function SDKEventTarget(appData: AppData) {
  async function __dispatchEvent<T extends EventTypeStrings = EventTypeStrings>(
    event: CustomEvent<EventPayload<T>>
  ): Promise<void> {
    const eventTarget = getEventTargetForCurrentContext(appData)
    eventTarget.dispatchEvent(event)
  }

  /**
   * @doc EventTarget
   * @note `Classic Editor` `Editor X`
   * @example
   * const undoHandler = (event) => { console.log(event.type, event.detail) };
   * await editorSDK.addEventListener('undo', undoHandler);
   * @param type - A string that specifies the event type to listen for ([list of available event types](../app/enums/_api_events_eventtype_.eventtype.md)).
   * @param handler - The function to call when an event occurs. This function returns nothing and accepts a single parameter: an object based on [`CustomEvent`](https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent) describing the event that occurred.
   * @param options
   *  - once: Boolean that when set to *true* indicates that the handler is called only on the first event, and then, is automatically removed. *false* by default.
   * @description This function registers an event listener. When an event occurs,
   * a *handler* function is called with a single parameter: an object based on [`CustomEvent`](https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent)
   * with a *type* property containing the event's type and a *detail* property containing
   * additional event data, if any.
   * `addEventListener()` works with both "general" and "custom" event types,
   * so you don't need to call `document.application.registerToCustomEvents('', { eventTypes: [someEventType] })`
   * when calling `editorSDK.addEventListener(someEventType, (event) => { … })`. Learn more about platform events in [this article](../articles/events.md).
   * `editorSDK.addEventListener()` aims to replicate the standard `EventTarget.addEventListener()`
   * method's behavior, so check out [its MDN documentation](https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener)
   * for more info.
   */
  async function addEventListener<
    T extends EventTypeStrings = EventTypeStrings
  >(
    type: T,
    handler: EventHandler<T>,
    options?: {once?: boolean}
  ): Promise<void> {
    if (isInDefaultContext(appData)) {
      const api = await getAPI()
      const token = ''

      api.document.application.registerToCustomEvents(appData, token, {
        applicationId: appData.applicationId,
        eventTypes: [type],
      })
    }

    const eventTarget = getEventTargetForCurrentContext(appData)

    eventTarget.addEventListener(type, handler, options)
  }

  /**
   * @doc EventTarget
   * @note `Classic Editor` `Editor X`
   * @example
   * await editorSDK.removeEventListener('undo', undoHandler);
   * @param type - A string that specifies the event type for which to remove an event listener  ([list of available event types](../app/enums/_api_events_eventtype_.eventtype.md)).
   * @param handler - The event handler function to remove. Has to be the same _instance_ as the one used in `editorSDK.addEventListener()`.
   * @description This function removes an event listener previously registered
   * with [addEventListener()](#addeventlistener). Learn more about platform events in [this article](../articles/events.md).
   * `editorSDK.removeEventListener()` aims to replicate standard `EventTarget.removeEventListener()`
   * method's behavior, so check out [its MDN documentation](https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener)
   * for more info.
   */
  async function removeEventListener<
    T extends EventTypeStrings = EventTypeStrings
  >(type: T, handler: EventHandler<T>): Promise<void> {
    const eventTarget = getEventTargetForCurrentContext(appData)

    eventTarget.removeEventListener(type, handler)
  }

  /**
   * @doc EventTarget
   * @note `Classic Editor` `Editor X`
   * @example
   * await editorSDK.removeAllListeners();
   * @description This function removes all event listeners previously registered by the application
   * with [addEventListener()](#addeventlistener). Learn more about platform events in [this article](../articles/events.md).
   */
  async function removeAllListeners(): Promise<void> {
    if (
      isInDefaultContext(appData) &&
      applicationIdEventTargetMap[appData.applicationId]
    ) {
      delete applicationIdEventTargetMap[appData.applicationId]
    } else if (
      isInExtensionContext(appData) &&
      extenstionNameEventTargetMap[appData.appDefinitionId]
    ) {
      delete extenstionNameEventTargetMap[appData.appDefinitionId]
    } else {
      panelEventTarget = null
    }
  }

  return {
    /**
     * @hidden
     * used in `santa-platform-apps-container` to trigger/dispatch events
     */
    __dispatchEvent,
    addEventListener,
    removeEventListener,
    /**
     * @hidden
     * used in `santa-platform-apps-container` to clear event listeners when application is 'hot' reloaded
     */
    removeAllListeners,
  }
}
