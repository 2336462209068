import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc History
   * @note `Classic Editor` `Editor X`
   * @description Adds a snapshot to undo redo stack with the given label. This function allows the user to perform undo from the editor and go
   * back to how the json was at the time the snapshot was taken.
   * @example editorSDK.document.history.add('token', {label: 'componentsWereAdded'})
   * @param token - app token - not in use
   * @param options -
   *  - label: description the snapshot and the changes done on the json.
   * @returns a Promise
   */
  function add(token: string, options: {label: string}): Promise<void> {
    return getAPI().then((api) => {
      return api.document.history.add(appData, token, options)
    })
  }

  return {
    add,
  }
}
