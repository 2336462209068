import {getAPI} from '../../privates/editorAPI'

export default function (appData) {
  /**
   * @doc PageUriSEO
   * @description Convert the page name string to a pageUriSEO string.
   * @example const url = await editorSDK.document.pages.pageUriSEO.convertPageNameToUrl('token', {title: 'Page Name'}) //Returns page-name
   * @param token - app token - not in use
   * @param options -
   * - `title`: The name to convert
   * @returns Returns the pageUriSEO string
   */
  function convertPageNameToUrl(
    token: string,
    options: {title: string}
  ): Promise<string> {
    return getAPI().then((api) =>
      api.document.pages.pageUriSEO.convertPageNameToUrl(
        appData,
        token,
        options
      )
    )
  }

  return {
    convertPageNameToUrl,
  }
}
