import {ComponentRef, Layout} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export type LayoutUpdateObject = Partial<Layout>

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @description Get the layout of the given componentRef.
   * @doc Layout
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const layout = await editorSDK.components.layout.get('token', {componentRef: componentRef});
   * @param token - app token - not in use
   * @param options -
   * - `componentRef`: the component ref.
   * @returns a promise that is resolved with the layout of the given componentRef.
   */
  function get(token, options: {componentRef: ComponentRef}): Promise<Layout> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.layout.get(appData, token, options)
    )
  }

  /**
   * @description Update the layout of the given componentRef.
   * @doc Layout
   * @example
   const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   await editorSDK.components.layout.update('token', {componentRef: componentRef, layout: {x: 200, y: 300}});
   * @param token - app token - not in use
   * @param options -
   * - `componentRef`: the component ref.
   * - `layout`: an object containing the layout fields to update.
   * @returns a promise that is resolved when the changes has applied to the document.
   */
  function update(
    token,
    options: {componentRef: ComponentRef; layout: LayoutUpdateObject}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) => api.document.components.layout.update(appData, token, options)
    )
  }

  return {
    get,
    update,
  }
}
