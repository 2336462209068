import {ComponentRef} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP
  /**
   * @description Update the component's [stylable](https://github.com/wix/stylable) properties inside its style object.
   * @doc Stylable
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * await editorSDK.components.stylable.update('token', {
   *   componentRef: componentRef,
   *   style: {
   *     style: {
   *       properties: {
   *         '$st-css': `:import {
   *           -st-from: 'wix-ui-santa/index.st.css';
   *           -st-named: StylableButton;
   *           }
   *           .root {
   *             -st-extends: StylableButton;
   *             transition: all 0.2s ease, visibility 0s;
   *             background: #FFFFFF;
   *           }
   *           .root::icon {
   *             display: none;
   *           }`,
   *       },
   *     },
   *  }});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: The reference to the component.
   * - style: An object containing the style properties to update.
   * @returns A promise that is resolved when the changes have been applied to the document.
   */
  function update(
    token,
    options: {componentRef: ComponentRef; style: unknown}
  ) {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) => api.document.components.stylable.update(appData, token, options)
    )
  }

  return {
    update,
  }
}
