import apiWrapper from '../../privates/apiWrapper'
import {
  CollapsedExpandedComponentRef,
  ComponentRef,
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
} from '@wix/editor-platform-sdk-types'
export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData
) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc RefComponents
   * @description Removes all component overrides, such as text or style changes,
   * for the specified closed widget. Works only for a closed widget.
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-1234'})
   * await editorSDK.components.remove('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - `componentRef`: `ComponentRef` The pointer to the widget.
   * @returns A promise that is resolved when the changes are applied to the document.
   */
  function removeAllOverrides(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<null> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) =>
        api.document.components.refComponents.removeAllOverrides(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc RefComponents
   * @description Opens the widget. Works only for a closed widget.
   * An open widget does not apply automatic updates, and the user can change its layout.
   * @example
   * await editorSDK.components.refComponents.openReferredComponent('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - `componentRef`: `ComponentRef` The pointer to the widget.
   * @returns A promise to the newly-opened widget (componentRef).
   */
  function openReferredComponent(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<ComponentRef> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) =>
        api.document.components.refComponents.openReferredComponent(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc RefComponents
   * @description Closes a widget. Works only for an open widget. A closed widget gets its rendering data from a remote structure,
   * and applies automatic updates. A user cannot change the layout of a closed widget.
   * @example
   * await editorSDK.components.refComponents.closeWidgetToReferredComponent('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - `componentRef`: `ComponentRef` The pointer to the widget.
   * @returns A promise to the newly-closed widget (componentRef).
   */
  function closeWidgetToReferredComponent(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<ComponentRef> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) =>
        api.document.components.refComponents.closeWidgetToReferredComponent(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc RefComponents
   * @description Returns an array of all the refComponents on the site that belong to the requesting app.
   * @example
   * const allAppRefComponents = await editorSDK.document.components.refComponents.getAllAppRefComponents('token');
   * allAppRefComponents.forEach(compRef => {});
   * @param {string} token - app token, not in use
   * @param options (Required only in Editor Extensions context)
   * - appDefinitionId: The unique ID of the application whose ref components you want to get.
   * @returns A promise that is resolved with an array of refComponents.
   */
  function getAllAppRefComponents(
    token,
    options: ContextAwareOptions<Context, void, {appDefinitionId: string}>
  ): Promise<ComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) =>
        api.document.components.refComponents.getAllAppRefComponents(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc RefComponents
   * @description For a passed referred component, returns the template component, otherwise, returns *undefined*.
   * @example
   * const compTemplate = await editorSDK.document.components.refComponents.getTemplateComponent('token', {componentRef});
   * console.log(compTemplate) //{id: 'comp-template', type: 'DESKTOP'}
   * @example
   * const compTemplate = await editorSDK.document.components.refComponents.getTemplateComponent('token', {componentRef});
   * console.log(compTemplate) //undefined
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - `componentRef`: `ComponentRef` the pointer to the referred component.
   * @returns A promise that is resolved with a ComponentRef (the template component) or *undefined*.
   */
  function getTemplateComponent(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<ComponentRef | undefined> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) =>
        api.document.components.refComponents.getTemplateComponent(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc RefComponents
   * @description Returns an array of pairs - *[{componentRef, role}]* for all of the collapsed components of a specified refComponent.
   * @example
   * const allCollapsedComponentsRefs = await editorSDK.document.components.refComponents.getCollapsedRefComponents('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - componentRef: The pointer to the referred widget (the pointer to the RefComponent)
   * @returns A promise that is resolved with an array of pairs - *[{componentRef, role}]* for all of the collapsed components of a specified refComponent.
   */
  function getCollapsedRefComponents(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<CollapsedExpandedComponentRef[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) =>
        api.document.components.refComponents.getCollapsedRefComponents(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc RefComponents
   * @description Returns true if the referred component collapses (becomes hidden).
   * @example
   * const isReferredCompCollapsed = await editorSDK.document.components.refComponents.isRefComponentCollapsed('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - `componentRef`:The pointer to the component.
   * @returns A Boolean that is true when a component collapses.
   */
  function isRefComponentCollapsed(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<boolean> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.components.refComponents.isRefComponentCollapsed(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc RefComponents
   * @description Collapse (hide) components inside a closed (byRef) widget. [Learn more](../articles/byref-components.md#closed-and-open-widgets)
   * @example
   * await editorSDK.document.components.refComponents.collapseReferredComponent('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - componentRef: The pointer to the component.
   * @returns A promise that is resolved when the component collapses.
   */
  function collapseReferredComponent(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<boolean> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.components.refComponents.collapseReferredComponent(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc RefComponents
   * @description Expand (make visible) components inside a closed (byRef) widget. [Learn more](../articles/byref-components.md#closed-and-open-widgets)
   * @example
   * await editorSDK.document.components.refComponents.expandReferredComponent('token', {componentRef: componentRef});
   * @param {string} token - app token, not in use
   * @param {Object} options -
   * - componentRef: The pointer to the component.
   * @returns A promise that is resolved when the component expanded.
   */
  function expandReferredComponent(
    token,
    options: {componentRef: ComponentRef}
  ): Promise<boolean> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.components.refComponents.expandReferredComponent(
          appData,
          token,
          options
        )
    )
  }

  return {
    removeAllOverrides,
    openReferredComponent,
    closeWidgetToReferredComponent,
    getAllAppRefComponents,
    getTemplateComponent,
    isRefComponentCollapsed,
    getCollapsedRefComponents,
    collapseReferredComponent,
    expandReferredComponent,
  }
}
