import {getAPI} from '../../privates/editorAPI'
import {GroupPermissions} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @doc Permissions
   * @description Checks whether a group permission is granted to the current user. [Learn more](../articles/permissions.md)
   * @example const groupPermissionGranted = await editorSDK.document.permissions.isGroupsPermissionsGranted('token', {permissions: ['EDIT_CONTENT']});
   * @param token - app token - not in use
   * @param options -
   *  - permissions: The permissions to be checked.
   * @returns A promise that is resolved with a Boolean that is *true* if the permission is granted or *false* if it is not granted. If the permission is not supported by the editor, the function returns a default value of *true*.
   */
  function isGroupsPermissionsGranted(
    token: string,
    options: {permissions: GroupPermissions[]}
  ): Promise<boolean> {
    return getAPI().then((api) =>
      api.document.permissions.isGroupsPermissionsGranted(
        appData,
        token,
        options
      )
    )
  }

  /**
   * @doc Permissions
   * @description Checks whether a custom permission is granted to the current user. [Learn more](../articles/permissions.md)
   * @example const customPermissionsGranted = await editorSDK.document.permissions.isCustomPermissionsGranted('token', {permissions: ['open_tpa_setting']});
   * @param token - app token - not in use
   * @param options -
   *  - permissions: The permissions to be checked.
   * @returns A promise that is resolved with a Boolean that is *true* if the permission is granted or *false* if it is not granted. If the permission is not supported by the editor, the function returns a default value of *true*.
   */
  function isCustomPermissionsGranted(
    token: string,
    options: {permissions: string[]}
  ): Promise<boolean> {
    return getAPI().then((api) =>
      api.document.permissions.isCustomPermissionsGranted(
        appData,
        token,
        options
      )
    )
  }

  return {
    isGroupsPermissionsGranted,
    isCustomPermissionsGranted,
  }
}
