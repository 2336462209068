import {PageRef} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc Pages.Permissions
   * @description Gets an indication if a page is protected by a password.
   * @example const doesPageHavePassword = await editorSDK.document.pages.permissions.hasPassword('token', {pageRef : {id: 'oy3rh'}})
   * @param token - app token - not in use
   * @param options -
   * - `pageRef`: The page to check.
   * @returns - A promise with boolean that indicates if a page is protected by a password.
   */
  function hasPassword(
    token: string,
    options: {pageRef: PageRef}
  ): Promise<boolean> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.pages.permissions.hasPassword(appData, token, options)
    )
  }

  /**
   * @doc Pages.Permissions
   * @description Sets a password for a page.
   * @example await editorSDK.document.pages.permissions.updatePagePassword('token', {pageRef : {id: 'oy3rh'}, plainPassword: 'superSecretPass'})
   * @param token - app token - not in use
   * @param options -
   * - `pageRef`: The page to set the password for.
   * - `plainPassword`: Password
   * @returns - A promsie that is resolved when password is updated
   */
  function updatePagePassword(
    token: string,
    options: {pageRef: PageRef; plainPassword: string}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.pages.permissions.updatePagePassword(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Pages.Permissions
   * @description Remove password protection from a page.
   * @example await editorSDK.document.pages.permissions.removePagePassword('token', {pageRef : {id: 'oy3rh'}})
   * @param token - app token - not in use
   * @param options -
   * - `pageRef`: Page to remove password protection from.
   * @returns - A promsie that is resolved when the password is removed from the page.
   */
  function removePagePassword(
    token: string,
    options: {pageRef: PageRef}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.pages.permissions.removePagePassword(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Pages.Permissions
   * @description Clones the password from one page to another page.
   * @example await editorSDK.document.pages.permissions.duplicatePagePassword('token', {destinationPageRef : {id: 'oy3rh'}, sourcePageRef : {id: 'oy3rh'}})
   * @param token - app token - not in use
   * @param options -
   * - `sourcePageRef`: The page to copy the password from.
   * - `destinationPageRef`: The page to copy the password to.
   * @returns {Promise} Resolves upon completion.
   */
  function duplicatePagePassword(
    token: string,
    options: {sourcePageRef: PageRef; destinationPageRef: PageRef}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [options.sourcePageRef, options.destinationPageRef],
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.pages.permissions.duplicatePagePassword(
          appData,
          token,
          options
        )
    )
  }

  /**
   * @doc Pages.Permissions
   * @description Duplicate one or more group permissions from one page to another.
   * @example await editorSDK.document.pages.permissions.duplicateGroupsPermissions('token', {sourcePageRef : {id: 'oy3rh'}, destinationPageRef : {id: 'c1dmp'}})
   * @param token - app token - not in use
   * @param options -
   * - sourcePageRef: Page to get the group permissions from.
   * - destinationPageRef: Page to add the group permissions to.
   * @returns {Promise} Resolves upon completion.
   */
  function duplicateGroupsPermissions(
    token: string,
    options: {sourcePageRef: PageRef; destinationPageRef: PageRef}
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [options.sourcePageRef, options.destinationPageRef],
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.pages.permissions.duplicateGroupsPermissions(
          appData,
          token,
          options
        )
    )
  }

  return {
    hasPassword,
    updatePagePassword,
    removePagePassword,
    duplicatePagePassword,
    duplicateGroupsPermissions,
  }
}
